import { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { collection, getDocs, deleteDoc, doc, setDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Download, Users, AlertCircle } from 'lucide-react';
import { toast } from 'react-hot-toast';

interface UserData {
  uid: string;
  email: string;
  subscriptionStatus: 'free' | 'pro';
  lastLogin?: Date;
  createdAt?: Date;
}

interface DeleteConfirmationProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => Promise<void>;
  userEmail: string;
}

const DeleteConfirmation = ({ isOpen, onClose, onConfirm, userEmail }: DeleteConfirmationProps) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full mx-4">
        <div className="flex items-center gap-3 mb-4">
          <AlertCircle className="w-6 h-6 text-red-500" />
          <h3 className="text-lg font-semibold text-gray-900">Delete Account</h3>
        </div>
        <p className="text-gray-600 mb-6">
          Are you sure you want to delete the account for {userEmail}? This action cannot be undone.
        </p>
        <div className="flex justify-end gap-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 text-sm font-medium text-white bg-red-500 rounded-lg hover:bg-red-600 transition-colors"
          >
            Delete Account
          </button>
        </div>
      </div>
    </div>
  );
};

export default function AdminDashboard() {
  const [users, setUsers] = useState<UserData[]>([]);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const [deleteConfirmation, setDeleteConfirmation] = useState<{
    isOpen: boolean;
    userId: string;
    userEmail: string;
  }>({ isOpen: false, userId: '', userEmail: '' });

  useEffect(() => {
    const fetchUsers = async () => {
      if (user?.email !== 'commsly.contact@gmail.com') {
        return;
      }

      try {
        const usersRef = collection(db, 'users');
        const snapshot = await getDocs(usersRef);
        const userData = snapshot.docs.map(doc => ({
          uid: doc.id,
          ...doc.data(),
          lastLogin: doc.data().lastLogin?.toDate(),
          createdAt: doc.data().createdAt?.toDate(),
        })) as UserData[];

        setUsers(userData);
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [user]);

  const downloadCSV = () => {
    const headers = ['Email', 'Plan', 'Last Login', 'Created At'];
    const csvData = users.map(user => [
      user.email,
      user.subscriptionStatus,
      user.lastLogin ? new Date(user.lastLogin).toLocaleString() : 'N/A',
      user.createdAt ? new Date(user.createdAt).toLocaleString() : 'N/A'
    ]);

    const csvContent = [
      headers.join(','),
      ...csvData.map(row => row.join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `users-${new Date().toISOString().split('T')[0]}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const handleDeleteUser = async (userId: string, userEmail: string) => {
    if (userEmail === 'commsly.contact@gmail.com') {
      toast.error("Cannot delete admin account");
      return;
    }
    setDeleteConfirmation({ isOpen: true, userId, userEmail });
  };

  const confirmDelete = async () => {
    try {
      const { userId, userEmail } = deleteConfirmation;
      
      // Delete user document from Firestore
      await deleteDoc(doc(db, 'users', userId));
      
      // Update local state
      setUsers(users.filter(user => user.email !== userEmail));
      
      toast.success('Account deleted successfully');
    } catch (error) {
      console.error('Error deleting user:', error);
      toast.error('Failed to delete account');
    } finally {
      setDeleteConfirmation({ isOpen: false, userId: '', userEmail: '' });
    }
  };

  const handlePlanChange = async (userId: string, newPlan: 'free' | 'pro') => {
    try {
      const userRef = doc(db, 'users', userId);
      await setDoc(userRef, {
        subscriptionStatus: newPlan,
        planType: newPlan,
        maxReleases: newPlan === 'pro' ? 20 : 2,
        planFeatures: newPlan === 'pro' 
          ? ['all_templates', 'priority_support', 'advanced_analytics']
          : ['basic_templates'],
        ...(newPlan === 'pro' ? {
          subscriptionStartDate: new Date().toISOString(),
        } : {
          subscriptionEndDate: new Date().toISOString(),
          subscriptionId: null
        })
      }, { merge: true });

      // Update local state
      setUsers(users.map(user => 
        user.uid === userId 
          ? { ...user, subscriptionStatus: newPlan }
          : user
      ));

      toast.success(`User plan updated to ${newPlan}`);
    } catch (error) {
      console.error('Error updating user plan:', error);
      toast.error('Failed to update user plan');
    }
  };

  if (user?.email !== 'commsly.contact@gmail.com') {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-2xl font-bold text-gray-900 mb-2">Access Denied</h1>
          <p className="text-gray-600">You don't have permission to view this page.</p>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-2xl font-bold text-gray-900 mb-2">Loading...</h1>
          <p className="text-gray-600">Please wait while we fetch the data.</p>
        </div>
      </div>
    );
  }

  const totalUsers = users.length;
  const proUsers = users.filter(user => user.subscriptionStatus === 'pro').length;
  const freeUsers = totalUsers - proUsers;

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="mb-8">
          <h1 className="text-3xl font-bold text-gray-900">Admin Dashboard</h1>
          <p className="mt-2 text-sm text-gray-600">Manage and monitor user data</p>
        </div>

        <div className="grid grid-cols-1 gap-5 sm:grid-cols-3 mb-8">
          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="p-5">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <Users className="h-6 w-6 text-gray-400" />
                </div>
                <div className="ml-5 w-0 flex-1">
                  <dl>
                    <dt className="text-sm font-medium text-gray-500 truncate">Total Users</dt>
                    <dd className="text-lg font-medium text-gray-900">{totalUsers}</dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="p-5">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <Users className="h-6 w-6 text-indigo-400" />
                </div>
                <div className="ml-5 w-0 flex-1">
                  <dl>
                    <dt className="text-sm font-medium text-gray-500 truncate">Pro Users</dt>
                    <dd className="text-lg font-medium text-gray-900">{proUsers}</dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="p-5">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <Users className="h-6 w-6 text-gray-400" />
                </div>
                <div className="ml-5 w-0 flex-1">
                  <dl>
                    <dt className="text-sm font-medium text-gray-500 truncate">Free Users</dt>
                    <dd className="text-lg font-medium text-gray-900">{freeUsers}</dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white shadow rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-medium text-gray-900">User List</h2>
              <button
                onClick={downloadCSV}
                className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 transition-colors"
              >
                <Download className="w-4 h-4" />
                Export CSV
              </button>
            </div>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Email
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Plan
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Last Login
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Created At
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {users.map((user, index) => (
                    <tr key={index}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        <div className="flex items-center gap-2">
                          {user.email}
                          {user.email === 'commsly.contact@gmail.com' && (
                            <span className="px-2 py-0.5 text-xs font-medium text-indigo-600 bg-indigo-100 rounded-full">
                              Admin
                            </span>
                          )}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center gap-2">
                          <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                            user.subscriptionStatus === 'pro' 
                              ? 'bg-green-100 text-green-800' 
                              : 'bg-gray-100 text-gray-800'
                          }`}>
                            {user.subscriptionStatus}
                          </span>
                          <button
                            onClick={() => handlePlanChange(
                              user.uid, 
                              user.subscriptionStatus === 'pro' ? 'free' : 'pro'
                            )}
                            className={`px-2 py-1 text-xs font-medium rounded ${
                              user.subscriptionStatus === 'pro'
                                ? 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                                : 'bg-indigo-100 text-indigo-600 hover:bg-indigo-200'
                            } transition-colors`}
                          >
                            {user.subscriptionStatus === 'pro' ? 'Downgrade' : 'Upgrade'}
                          </button>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {user.lastLogin ? new Date(user.lastLogin).toLocaleString() : 'N/A'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {user.createdAt ? new Date(user.createdAt).toLocaleString() : 'N/A'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap flex gap-2">
                        <button
                          onClick={() => handleDeleteUser(user.uid, user.email)}
                          disabled={user.email === 'commsly.contact@gmail.com'}
                          className={`px-3 py-1 rounded-md text-sm font-medium ${
                            user.email === 'commsly.contact@gmail.com'
                              ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                              : 'bg-red-100 text-red-600 hover:bg-red-200 transition-colors'
                          }`}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <DeleteConfirmation
          isOpen={deleteConfirmation.isOpen}
          onClose={() => setDeleteConfirmation({ isOpen: false, userId: '', userEmail: '' })}
          onConfirm={confirmDelete}
          userEmail={deleteConfirmation.userEmail}
        />
      </div>
    </div>
  );
} 