import { Check, X } from 'lucide-react';
import { createPortal } from 'react-dom';
import { useAuth } from '../contexts/AuthContext';
import { toast } from 'react-hot-toast';

interface PricingModalProps {
  isOpen: boolean;
  onClose: () => void;
  currentPlan: 'free' | 'pro';
  releasesCount: number;
}

export default function PricingModal({ isOpen, onClose, currentPlan, releasesCount }: PricingModalProps) {
  const { user } = useAuth();

  const handleSubscribe = async (plan: string) => {
    if (!user?.uid) {
      console.error('No user ID available');
      toast.error('Please log in to subscribe');
      return;
    }

    try {
      const baseUrl = plan === 'lifetime' 
        ? 'https://buy.stripe.com/7sI9Eg8lH9PH4xi8wx'
        : 'https://buy.stripe.com/00g2bO1Xje5Xgg0eUU';
        
      const checkoutUrl = new URL(baseUrl);
      
      checkoutUrl.searchParams.append('client_reference_id', user.uid);
      checkoutUrl.searchParams.append('plan_type', plan);
      
      if (user.email) {
        checkoutUrl.searchParams.append('prefilled_email', user.email);
      }
      
      console.log('Redirecting to checkout with params:', {
        client_reference_id: user.uid,
        plan_type: plan,
        email: user.email
      });
      
      window.location.href = checkoutUrl.toString();
      onClose();
    } catch (error) {
      console.error('Error redirecting to checkout:', error);
      toast.error('Failed to start checkout process');
    }
  };

  if (!isOpen) return null;

  const hasReachedLimit = currentPlan === 'free' && releasesCount >= 2;

  const modal = (
    <div 
      className="fixed inset-0 bg-gray-900/30 backdrop-blur-sm overflow-y-auto" 
      style={{ zIndex: 999999 }}
    >
      <div className="min-h-screen px-4 text-center">
        <span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span>
        
        <div className="inline-block w-full max-w-4xl p-8 my-8 text-left align-middle bg-white rounded-xl shadow-2xl transform transition-all">
          <div className="flex justify-between items-start mb-8">
            <div>
              <h2 className="text-2xl font-bold text-gray-900">Choose Your Plan</h2>
              {hasReachedLimit ? (
                <p className="text-gray-600 mt-2">
                  You've reached the limit of 2 press releases on the free tier. Upgrade to create more!
                </p>
              ) : (
                <p className="text-gray-600 mt-2">Unlock more features with our Pro plan</p>
              )}
            </div>
            <button
              onClick={onClose}
              className="p-2 text-gray-400 hover:text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
            >
              <X className="w-5 h-5" />
            </button>
          </div>

          <div className="grid md:grid-cols-2 gap-8">
            {/* Free Tier */}
            <div className={`relative rounded-xl p-6 ${
              currentPlan === 'free' ? 'bg-blue-50 border-2 border-blue-500' : 'bg-white border border-gray-200'
            }`}>
              {currentPlan === 'free' && (
                <div className="absolute -top-3 left-1/2 -translate-x-1/2 bg-blue-500 text-white px-3 py-1 rounded-full text-sm font-medium">
                  Current Plan
                </div>
              )}
              <div className="mb-4">
                <h3 className="text-xl font-semibold text-gray-900">Free Tier</h3>
                <p className="text-gray-600 mt-2">Perfect for getting started</p>
              </div>
              <div className="mb-6">
                <div className="text-3xl font-bold text-gray-900">$0</div>
                <div className="text-gray-600">Forever free</div>
              </div>
              <ul className="space-y-3 mb-6">
                <li className="flex items-center gap-2">
                  <Check className="w-5 h-5 text-green-500" />
                  <span>2 Press Releases</span>
                </li>
                <li className="flex items-center gap-2">
                  <Check className="w-5 h-5 text-green-500" />
                  <span>Basic Templates</span>
                </li>
                <li className="flex items-center gap-2">
                  <Check className="w-5 h-5 text-green-500" />
                  <span>PDF Export</span>
                </li>
              </ul>
              {releasesCount >= 2 && currentPlan === 'free' && (
                <div className="text-sm text-red-600 mb-4">
                  You've reached the limit of free press releases
                </div>
              )}
            </div>

            {/* Pro Tier */}
            <div className={`relative rounded-xl p-6 ${
              currentPlan === 'pro' ? 'bg-indigo-50 border-2 border-indigo-500' : 'bg-white border border-gray-200'
            }`}>
              {currentPlan === 'pro' && (
                <div className="absolute -top-3 left-1/2 -translate-x-1/2 bg-indigo-500 text-white px-3 py-1 rounded-full text-sm font-medium">
                  Current Plan
                </div>
              )}
              <div className="mb-4">
                <h3 className="text-xl font-semibold text-gray-900">Pro Tier</h3>
                <p className="text-gray-600 mt-2">For serious professionals</p>
              </div>
              <div className="mb-6">
                <div className="text-3xl font-bold text-gray-900">$5</div>
                <div className="text-gray-600">per month</div>
              </div>
              <ul className="space-y-3 mb-6">
                <li className="flex items-center gap-2">
                  <Check className="w-5 h-5 text-green-500" />
                  <span>50 Press Releases</span>
                </li>
                <li className="flex items-center gap-2">
                  <Check className="w-5 h-5 text-green-500" />
                  <span>Premium Templates</span>
                </li>
                <li className="flex items-center gap-2">
                  <Check className="w-5 h-5 text-green-500" />
                  <span>Priority Support</span>
                </li>
                <li className="flex items-center gap-2">
                  <Check className="w-5 h-5 text-green-500" />
                  <span>Advanced Analytics</span>
                </li>
              </ul>
              {currentPlan !== 'pro' && (
                <button
                  onClick={() => handleSubscribe('pro')}
                  className="w-full py-2 px-4 bg-gradient-to-r from-indigo-500 to-blue-500 text-white rounded-lg font-medium hover:from-indigo-600 hover:to-blue-600 transition-all shadow-sm hover:shadow"
                >
                  Upgrade to Pro
                </button>
              )}
            </div>
          </div>

          <div className="mt-8 text-center text-sm text-gray-600">
            Questions? Contact our support team at commsly.contact@gmail.com
          </div>
        </div>
      </div>
    </div>
  );

  return createPortal(modal, document.body);
}