import { useState } from 'react';
import { PlusCircle, Type, Image, BarChart3, Share2, ChevronRight, ChevronLeft, Link } from 'lucide-react';
import { BlockType, TextBlockType, MediaBlockType, SocialBlockType } from '../types';

interface BlockToolbarProps {
  onAddBlock: (type: BlockType) => void;
}

const TEXT_BLOCKS: { type: TextBlockType; label: string }[] = [
  { type: 'headline', label: 'Headline' },
  { type: 'subheading', label: 'Subheading' },
  { type: 'body', label: 'Body Text' },
  { type: 'quote', label: 'Quote' },
  { type: 'cta', label: 'Call to Action' },
  { type: 'contact', label: 'Contact Info' },
  { type: 'footer', label: 'Footer' },
];

const MEDIA_BLOCKS: { type: MediaBlockType; label: string }[] = [
  { type: 'image', label: 'Image' },
  { type: 'video', label: 'Video' },
  { type: 'chart', label: 'Chart' },
  { type: 'link', label: 'Website Link' },
];

const SOCIAL_BLOCKS: { type: SocialBlockType; label: string }[] = [
  { type: 'twitter', label: 'X (Twitter)' },
  { type: 'facebook', label: 'Facebook' },
  { type: 'instagram', label: 'Instagram' },
  { type: 'youtube', label: 'YouTube' },
];

export default function BlockToolbar({ onAddBlock }: BlockToolbarProps) {
  const [activeTab, setActiveTab] = useState<'text' | 'media' | 'social'>('text');
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="fixed right-0 top-1/2 -translate-y-1/2 flex items-start z-50">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center gap-2 bg-gradient-to-r from-indigo-500 to-blue-500 text-white shadow-sm rounded-l-lg py-2 px-3 -ml-px hover:from-indigo-600 hover:to-blue-600 transition-all"
      >
        {isOpen ? (
          <>
            <ChevronRight className="w-4 h-4" />
            <span className="sr-only">Close Toolbar</span>
          </>
        ) : (
          <>
            <ChevronLeft className="w-4 h-4" />
            <span className="text-sm font-medium"></span>
          </>
        )}
      </button>

      <div className={`bg-white/90 backdrop-blur-sm border border-blue-100/50 shadow-lg rounded-l-lg transition-all duration-300 ${
        isOpen ? 'w-64 opacity-100' : 'w-0 opacity-0 overflow-hidden'
      }`}>
        <div className="max-h-[600px] flex flex-col">
          <div className="flex border-b border-gray-200">
            <button
              onClick={() => setActiveTab('text')}
              className={`flex-1 flex items-center gap-2 px-3 py-2 text-sm justify-center transition-colors ${
                activeTab === 'text'
                  ? 'text-indigo-600 bg-indigo-50'
                  : 'text-gray-600 hover:bg-gray-50'
              }`}
            >
              <Type className="w-4 h-4" />
              Text
            </button>
            <button
              onClick={() => setActiveTab('media')}
              className={`flex-1 flex items-center gap-2 px-3 py-2 text-sm justify-center transition-colors ${
                activeTab === 'media'
                  ? 'text-indigo-600 bg-indigo-50'
                  : 'text-gray-600 hover:bg-gray-50'
              }`}
            >
              <BarChart3 className="w-4 h-4" />
              Media
            </button>
            <button
              onClick={() => setActiveTab('social')}
              className={`flex-1 flex items-center gap-2 px-3 py-2 text-sm justify-center transition-colors ${
                activeTab === 'social'
                  ? 'text-indigo-600 bg-indigo-50'
                  : 'text-gray-600 hover:bg-gray-50'
              }`}
            >
              <Share2 className="w-4 h-4" />
              Social
            </button>
          </div>

          <div className="p-2 overflow-y-auto">
            {activeTab === 'text' && (
              <div className="flex flex-col gap-1">
                {TEXT_BLOCKS.map(({ type, label }) => (
                  <button
                    key={type}
                    onClick={() => onAddBlock(type)}
                    className="flex items-center gap-2 px-3 py-2 text-sm text-gray-700 hover:bg-indigo-50 rounded-md transition-colors whitespace-nowrap group w-full"
                    title={label}
                  >
                    <PlusCircle className="w-4 h-4 text-gray-400 group-hover:text-indigo-500" />
                    <span>{label}</span>
                  </button>
                ))}
              </div>
            )}
            
            {activeTab === 'media' && (
              <div className="flex flex-col gap-1">
                {MEDIA_BLOCKS.map(({ type, label }) => (
                  <button
                    key={type}
                    onClick={() => onAddBlock(type)}
                    className="flex items-center gap-2 px-3 py-2 text-sm text-gray-700 hover:bg-indigo-50 rounded-md transition-colors whitespace-nowrap group w-full"
                    title={label}
                  >
                    {type === 'link' ? (
                      <Link className="w-4 h-4 text-gray-400 group-hover:text-indigo-500" />
                    ) : type === 'image' || type === 'video' ? (
                      <Image className="w-4 h-4 text-gray-400 group-hover:text-indigo-500" />
                    ) : (
                      <BarChart3 className="w-4 h-4 text-gray-400 group-hover:text-indigo-500" />
                    )}
                    <span>{label}</span>
                  </button>
                ))}
              </div>
            )}

            {activeTab === 'social' && (
              <div className="flex flex-col gap-1">
                {SOCIAL_BLOCKS.map(({ type, label }) => (
                  <button
                    key={type}
                    onClick={() => onAddBlock(type)}
                    className="flex items-center gap-2 px-3 py-2 text-sm text-gray-700 hover:bg-indigo-50 rounded-md transition-colors whitespace-nowrap group w-full"
                    title={label}
                  >
                    <Share2 className="w-4 h-4 text-gray-400 group-hover:text-indigo-500" />
                    <span>{label}</span>
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}