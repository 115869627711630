import { useState, useEffect } from 'react';
import { Routes, Route, useSearchParams } from 'react-router-dom';
import { Newspaper } from 'lucide-react';
import Editor from './components/Editor';
import LandingPage from './components/LandingPage';
import { auth } from './lib/firebase';
import { User } from 'firebase/auth';
import { useAuth } from './contexts/AuthContext';
import SettingsDropdown from './components/SettingsDropdown';
import AdminDashboard from './components/AdminDashboard';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SharedPressReleaseView from './components/SharedPressReleaseView';

interface ExtendedUser extends User {
  subscriptionStatus?: string;
  planType?: string;
  planFeatures?: string[];
  email: string | null;
}

function App() {
  const { user, loading: authLoading } = useAuth();
  const [hasStarted, setHasStarted] = useState(false);
  const [currentPlan, setCurrentPlan] = useState<'free' | 'pro'>('free');
  const [releasesCount, setReleasesCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchParams] = useSearchParams();
  const isSharedView = searchParams.has('share');

  console.log('App render:', { user, authLoading, hasStarted, currentPlan, isLoading });

  useEffect(() => {
    const initializeApp = async () => {
      try {
        if (!authLoading) {
          if (user) {
            console.log('Setting up user state:', user);
            const extendedUser = user as ExtendedUser;
            setCurrentPlan(extendedUser.subscriptionStatus === 'pro' ? 'pro' : 'free');
            setHasStarted(true);
          } else {
            console.log('Resetting user state');
            setHasStarted(false);
            setCurrentPlan('free');
          }
        }
      } catch (err: unknown) {
        const errorMessage = err instanceof Error ? err.message : 'Unknown error occurred';
        console.error('Firebase initialization error:', errorMessage);
        setError('An error occurred while initializing the application.');
      } finally {
        setIsLoading(false);
      }
    };

    initializeApp();
  }, [user, authLoading]);

  const handleCreateRelease = () => {
    setReleasesCount((prev: number) => prev + 1);
  };

  const handleStart = () => {
    console.log('handleStart called');
    setHasStarted(true);
  };

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      setHasStarted(false);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  if (authLoading || isLoading) {
    console.log('Showing loading screen');
    return (
      <div className="min-h-screen bg-gradient-to-br from-blue-50 via-indigo-50 flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-xl font-semibold text-gray-900 mb-2">Loading...</h2>
          <p className="text-gray-600">Please wait while we initialize the application.</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-blue-50 via-indigo-50 flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-xl font-semibold text-red-600 mb-2">Error</h2>
          <p className="text-gray-600">{error}</p>
        </div>
      </div>
    );
  }

  if (isSharedView) {
    return <SharedPressReleaseView />;
  }

  if (!hasStarted || !user) {
    console.log('Showing landing page');
    return <LandingPage onStart={handleStart} />;
  }

  if (user?.email === 'commsly.contact@gmail.com' && window.location.pathname === '/admin') {
    return <AdminDashboard />;
  }

  console.log('Showing main app');
  return (
    <Routes>
      <Route 
        path="/shared" 
        element={<SharedPressReleaseView />} 
      />
      <Route 
        path="/" 
        element={
          user ? (
            <div className="min-h-screen bg-gradient-to-br from-blue-50 via-indigo-50 to-purple-50">
              <ToastContainer position="top-right" autoClose={5000} />
              <header className="fixed top-0 left-0 right-0 z-[200] bg-white/70 backdrop-blur-md border-b border-gray-200/50">
                <div className="px-4">
                  <div className="flex items-center justify-between h-16">
                    <div className="flex items-center">
                      <Newspaper className="w-8 h-8 bg-gradient-to-r from-blue-600 to-indigo-600 text-white p-1.5 rounded-lg" />
                      <h1 className="ml-2 text-xl font-bold bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent">Releaseify</h1>
                    </div>
                    <div className="flex items-center gap-4">
                      <SettingsDropdown 
                        currentPlan={currentPlan}
                        onSignOut={handleSignOut}
                        onStart={handleStart}
                        releasesCount={releasesCount}
                      />
                    </div>
                  </div>
                </div>
              </header>

              <main className="pt-16">
                <Editor 
                  currentPlan={currentPlan}
                  releasesCount={releasesCount}
                  onCreateRelease={handleCreateRelease}
                  onSignOut={handleSignOut}
                />
              </main>
            </div>
          ) : (
            <LandingPage onStart={handleStart} />
          )
        } 
      />
    </Routes>
  );
}

export default App;