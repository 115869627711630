import html2pdf from 'html2pdf.js';
import { PressRelease, Block } from '../types';

const renderBlock = (block: Block, style: 'classic' | 'modern') => {
  const typographyClass = style === 'classic' 
    ? 'font-serif tracking-normal leading-relaxed'
    : 'font-sans tracking-tight leading-normal';

  switch (block.type) {
    case 'contact':
      return `
        <div style="margin: 0 0 1rem 0; padding: 0; text-align: left; position: relative; left: 0; display: flex; justify-content: space-between; align-items: start;">
          <div style="flex: 1;">
            <pre style="margin: 0; padding: 0; text-align: left; white-space: pre-wrap; font-family: inherit; position: relative; left: 0;" class="text-sm text-gray-600 ${typographyClass}">
${block.content}
            </pre>
          </div>
          ${block.mediaUrl ? `
            <div style="margin-left: 2rem; flex-shrink: 0;">
              <img src="${block.mediaUrl}" alt="Company Logo" style="max-width: 120px; max-height: 120px; object-fit: contain;"/>
            </div>
          ` : ''}
        </div>
        <hr style="margin: 1.5rem -0.75in; border: none; height: 1px; background-color: #000000; position: relative; left: 0; width: calc(100% + 1.5in);" />
      `;
    
    case 'headline':
      return `
        <h1 style="text-align: left;" class="text-3xl font-bold mb-6 ${
          style === 'classic' 
            ? 'font-serif text-gray-900 tracking-normal'
            : 'font-sans text-gray-900 tracking-tight'
        }">
          ${block.content}
        </h1>
      `;
    
    case 'subheading':
      return `
        <h2 style="text-align: left;" class="text-2xl font-semibold mb-4 ${
          style === 'classic'
            ? 'font-serif text-gray-800 tracking-normal'
            : 'font-sans text-gray-800 tracking-tight'
        }">
          ${block.content}
        </h2>
      `;
    
    case 'body':
      return `
        <p style="text-align: left;" class="mb-4 whitespace-pre-wrap ${
          style === 'classic'
            ? 'font-serif text-gray-700 leading-relaxed tracking-normal'
            : 'font-sans text-gray-700 leading-normal tracking-tight'
        }">
          ${block.content}
        </p>
      `;
    
    case 'quote':
      return `
        <blockquote style="text-align: left;" class="my-6 ${
          style === 'classic'
            ? 'font-serif border-l-4 border-gray-400 pl-4 italic'
            : 'font-sans border-l-4 border-indigo-500 pl-4'
        }">
          <p class="text-lg text-gray-700">${block.content}</p>
        </blockquote>
      `;
    
    case 'image':
      return block.mediaUrl ? `
        <figure style="text-align: left;" class="my-6">
          <img src="${block.mediaUrl}" alt="${block.content || 'Press release image'}" style="max-width: 100%; height: auto;"/>
          ${block.content ? `
            <figcaption class="mt-2 text-sm text-gray-500 ${typographyClass}">
              ${block.content}
            </figcaption>
          ` : ''}
        </figure>
      ` : '';
    
    case 'footer':
      return `
        <div style="margin-top: 2rem; padding-top: 1rem; border-top: 1px solid #E5E7EB;">
          <pre style="margin: 0; padding: 0; white-space: pre-wrap; font-family: inherit; font-size: 0.875rem; color: #4B5563; line-height: 1.6;" class="${typographyClass}">
${block.content}
          </pre>
        </div>
      `;
    
    case 'twitter':
    case 'facebook':
    case 'instagram':
    case 'youtube':
      return `
        <div style="margin: 1.5rem 0; padding: 1rem; background-color: #f9fafb; border-radius: 0.5rem; border: 1px solid #e5e7eb;">
          <p style="margin: 0; color: #4b5563; font-size: 0.875rem;">
            View the original post at: <a href="${block.content}" style="color: #4f46e5;">${block.content}</a>
          </p>
        </div>
      `;
    
    case 'cta':
      return `
        <div style="margin: 1.5rem 0; text-align: left;">
          <span style="
            display: inline-block;
            font-size: 1.125rem;
            font-weight: 600;
            color: rgb(79, 70, 229);
            border-bottom: 2px solid rgb(79, 70, 229);
            padding-bottom: 2px;
            margin-bottom: 2px;
            line-height: 1.5;
            ${style === 'classic' 
              ? 'font-family: serif;'
              : 'font-family: sans-serif;'
            }
          ">
            ${block.content}
          </span>
        </div>
      `;
    
    default:
      return '';
  }
};


export const exportToPdf = async (pressRelease: PressRelease) => {
  const element = document.createElement('div');
  element.className = 'pdf-container';
  
  // Add the content
  const content = pressRelease.blocks
    .map(block => renderBlock(block, pressRelease.style))
    .join('');
  
  element.innerHTML = `
    <div style="margin: 0; padding: 0; text-align: left; position: relative; left: 0;">
      <div style="margin: 0; padding: 0; text-align: left; position: relative; left: 0;">
        ${content}
      </div>
    </div>
  `;

  // Configure PDF options with equal left and right margins
  const options = {
    margin: [0.5, 0.75, 0.75, 0.75], // [top, right, bottom, left]
    filename: `${pressRelease.title || 'press-release'}.pdf`,
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { 
      scale: 2,
      useCORS: true,
      letterRendering: true,
      x: 0,
      scrollX: 0,
      scrollY: 0,
      windowWidth: document.documentElement.offsetWidth,
    },
    jsPDF: { 
      unit: 'in', 
      format: 'letter', 
      orientation: 'portrait',
      compress: true
    }
  };

  try {
    await html2pdf().set(options).from(element).save();
    return true;
  } catch (error) {
    console.error('PDF export failed:', error);
    return false;
  }
}; 