import { useState, useRef, useEffect } from 'react';
import { Settings, LogOut, CreditCard, User, CreditCard as BillingIcon, AlertCircle, Mail } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { toast } from 'react-hot-toast';
import PricingModal from './PricingModal';

interface SettingsDropdownProps {
  currentPlan: 'free' | 'pro';
  onSignOut: () => void;
  onStart?: () => void;
  releasesCount: number;
}

export default function SettingsDropdown({ currentPlan, onSignOut, onStart, releasesCount }: SettingsDropdownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [isPricingModalOpen, setIsPricingModalOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { user, logout, sendVerificationEmail } = useAuth();

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSignOut = async () => {
    await logout();
    onSignOut();
  };

  useEffect(() => {
    if (user && onStart) {
      onStart();
    }
  }, [user, onStart]);

  const handleResendVerification = async () => {
    try {
      await sendVerificationEmail();
      toast.success('Verification email sent! Please check your inbox.');
    } catch (error) {
      toast.error('Failed to send verification email. Please try again.');
    }
  };

  const handleManageSubscription = () => {
    if (currentPlan === 'free') {
      setIsPricingModalOpen(true);
      setIsOpen(false);
    } else {
      window.location.href = 'https://billing.stripe.com/p/login/7sIbKYcH810y2ek7ss';
    }
  };

  return (
    <>
      <div className="relative z-[100]" ref={dropdownRef}>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="p-2 text-gray-600 hover:text-gray-900 rounded-lg hover:bg-gray-100 transition-colors"
        >
          <Settings className="w-5 h-5" />
        </button>

        {isOpen && (
          <div className="absolute right-0 mt-2 w-72 bg-white rounded-lg shadow-lg py-2 z-[100] border border-gray-200">
            {user?.email === 'commsly.contact@gmail.com' && (
              <a
                href="/admin"
                className="flex items-center gap-2 px-4 py-2.5 text-sm text-gray-700 hover:bg-gray-100"
              >
                <User className="w-4 h-4" />
                Admin Dashboard
              </a>
            )}
            
            <div className="px-4 py-4 border-b border-gray-200">
              <div className="flex items-center gap-4">
                <div className="bg-gray-100 p-2.5 rounded-lg">
                  <User className="w-5 h-5 text-gray-600" />
                </div>
                <div>
                  <div className="text-sm font-medium text-gray-900 mb-0.5">Account</div>
                  <div className="text-sm text-gray-600">{user?.email}</div>
                </div>
              </div>
            </div>

            <div className="px-4 py-4 border-b border-gray-200">
              <div className="flex items-center gap-4">
                <div className="bg-gray-100 p-2.5 rounded-lg">
                  <CreditCard className="w-5 h-5 text-gray-600" />
                </div>
                <div>
                  <div className="text-sm font-medium text-gray-900 mb-0.5">Current Plan</div>
                  <div className="text-sm text-gray-600 capitalize flex items-center gap-2">
                    {currentPlan === 'pro' ? (
                      <span className="px-2 py-0.5 bg-green-100 text-green-700 rounded-md border border-green-200 font-medium">
                        Pro
                      </span>
                    ) : (
                      <span>{currentPlan}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 py-2 border-b border-gray-200">
              <button
                onClick={handleManageSubscription}
                className="flex items-center gap-3 w-full px-3 py-2.5 text-sm text-gray-600 hover:bg-gray-50 rounded-lg transition-colors"
              >
                <BillingIcon className="w-4 h-4" />
                {currentPlan === 'free' ? 'Upgrade to Pro' : 'Manage Subscription'}
              </button>
            </div>

            {user && !user.emailVerified && (
              <div className="px-4 py-3 border-b border-gray-200">
                <div className="flex items-start gap-2 p-2 bg-amber-50 rounded-lg">
                  <AlertCircle className="w-5 h-5 text-amber-500 flex-shrink-0 mt-0.5" />
                  <div className="flex-1">
                    <p className="text-sm text-amber-800 font-medium">Verify your email</p>
                    <p className="text-xs text-amber-700 mt-0.5 mb-2">
                      Please check your inbox and verify your email address to access all features.
                    </p>
                    <button
                      onClick={handleResendVerification}
                      className="flex items-center gap-2 px-3 py-1.5 text-xs font-medium text-amber-800 bg-amber-100 rounded-md hover:bg-amber-200 transition-colors"
                    >
                      <Mail className="w-3 h-3" />
                      Resend Verification Email
                    </button>
                  </div>
                </div>
              </div>
            )}

            <div className="px-3 py-2">
              <button
                onClick={handleSignOut}
                className="flex items-center gap-3 w-full px-3 py-2.5 text-sm text-red-600 hover:bg-red-50 rounded-lg transition-colors"
              >
                <LogOut className="w-4 h-4" />
                Sign Out
              </button>
            </div>
          </div>
        )}
      </div>

      <PricingModal
        isOpen={isPricingModalOpen}
        onClose={() => setIsPricingModalOpen(false)}
        currentPlan={currentPlan}
        releasesCount={releasesCount}
      />
    </>
  );
} 