import { Layout, CheckCircle2, Newspaper, Sparkles, X } from 'lucide-react';
import { Template, StyleType } from '../types';
import { AppUser } from '../contexts/AuthContext';

interface TemplateSelectorProps {
  isOpen: boolean;
  onClose: () => void;
  onSelectTemplate: (template: Template, style: StyleType) => void;
  templates: Template[];
  currentPlan: 'free' | 'pro';
  onUpgrade: () => void;
  userId: string;
  user: AppUser | null;
}

export default function TemplateSelector({
  isOpen,
  onClose,
  onSelectTemplate,
  templates,
  currentPlan,
  onUpgrade,
  userId,
  user,
}: TemplateSelectorProps) {
  if (!isOpen) return null;

  const freeTemplateIds = ['company-news'];

  const sortedTemplates = [...templates].sort((a, b) => {
    if (a.id === 'company-news') return -1;
    if (b.id === 'company-news') return 1;
    return 0;
  });

  const handleUpgrade = () => {
    const checkoutUrl = new URL('https://buy.stripe.com/00g2bO1Xje5Xgg0eUU');
    if (userId) {
      checkoutUrl.searchParams.append('client_reference_id', userId);
    }
    checkoutUrl.searchParams.append('plan_type', 'pro');
    checkoutUrl.searchParams.append('prefilled_email', user?.email || '');
    window.location.href = checkoutUrl.toString();
    onUpgrade();
  };

  return (
    <div className="fixed inset-0 bg-gradient-to-br from-blue-50 via-indigo-50 to-purple-50 flex items-center justify-center z-50">
      <div className="bg-white/80 backdrop-blur-sm rounded-2xl shadow-2xl w-full max-w-4xl mx-4 max-h-[90vh] overflow-y-auto border border-blue-100/50 relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 p-2 rounded-full hover:bg-gray-100/50 transition-colors"
          aria-label="Close"
        >
          <X className="w-5 h-5 text-gray-500" />
        </button>
        <div className="p-8 text-center">
          <div className="flex justify-center mb-4">
            <div className="bg-gradient-to-r from-blue-600 to-indigo-600 rounded-2xl p-3">
              <Newspaper className="w-10 h-10 text-white" />
            </div>
          </div>
          <h2 className="text-3xl font-bold bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent mb-3">
            Welcome to Releaseify!
          </h2>
          <p className="text-gray-600 max-w-xl mx-auto mb-12">
            Create and share press releases in minutes. Choose a template to get started or begin with a blank canvas.
          </p>

          <div className="grid grid-cols-2 gap-6 max-w-4xl mx-auto">
            <button
              onClick={onClose}
              className="group relative flex flex-col gap-4 p-6 text-left rounded-xl bg-white/50 hover:bg-white border border-blue-100/50 hover:border-blue-200/50 transition-all hover:shadow-lg"
            >
              <div className="flex items-center justify-center w-12 h-12 rounded-xl bg-gradient-to-br from-blue-500 to-indigo-500 text-white shadow-sm group-hover:shadow">
                <Layout className="w-6 h-6" />
              </div>
              <div>
                <h3 className="text-xl font-semibold text-gray-900 mb-2">Start Fresh</h3>
                <p className="text-gray-600 text-sm">Begin with a blank canvas and create your press release from scratch.</p>
              </div>
              <div className="absolute inset-0 border border-blue-500/0 group-hover:border-blue-500/10 rounded-xl transition-colors" />
            </button>

            {sortedTemplates.map((template) => {
              const isProTemplate = !freeTemplateIds.includes(template.id);
              const isDisabled = currentPlan === 'free' && isProTemplate;

              return (
                <div key={template.id} className="relative group">
                  {isDisabled && (
                    <div className="absolute -top-3 left-1/2 -translate-x-1/2 z-10">
                      <div className="bg-gradient-to-r from-indigo-500 to-blue-500 text-white text-xs font-medium px-3 py-1 rounded-full shadow-sm">
                        Pro Feature
                      </div>
                    </div>
                  )}
                  <button
                    onClick={() => !isDisabled && onSelectTemplate(template, 'modern')}
                    className={`w-full flex flex-col gap-4 p-6 text-left rounded-xl border transition-all
                      ${isDisabled 
                        ? 'bg-white/50 border-gray-200 hover:bg-gray-50 cursor-not-allowed' 
                        : 'bg-white/50 hover:bg-white border-blue-100/50 hover:border-blue-200/50 hover:shadow-lg'
                      }`}
                  >
                    <div className={`flex items-center justify-center w-12 h-12 rounded-xl shadow-sm
                      ${isDisabled
                        ? 'bg-gradient-to-br from-gray-100 to-gray-200'
                        : 'bg-gradient-to-br from-blue-500 to-indigo-500'
                      }`}
                    >
                      <CheckCircle2 className={`w-6 h-6 ${isDisabled ? 'text-gray-400' : 'text-white'}`} />
                    </div>
                    <div>
                      <div className="flex items-center gap-2">
                        <h3 className={`text-xl font-semibold ${isDisabled ? 'text-gray-600' : 'text-gray-900'}`}>
                          {template.title}
                        </h3>
                      </div>
                      <p className={`text-sm mt-1 ${isDisabled ? 'text-gray-500' : 'text-gray-600'}`}>
                        {template.description}
                      </p>
                    </div>
                  </button>
                </div>
              );
            })}
          </div>

          {currentPlan === 'free' && (
            <div className="mt-8 flex flex-col items-center">
              <div className="mb-2 text-sm text-gray-600">
                Want access to all premium templates?
              </div>
              <button
                onClick={handleUpgrade}
                className="inline-flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-indigo-500 to-blue-500 rounded-lg hover:from-indigo-600 hover:to-blue-600 transition-all shadow-sm hover:shadow"
              >
                <Sparkles className="w-4 h-4" />
                Upgrade to Pro
              </button>
            </div>
          )}

          <div className="mt-12 flex items-center justify-center gap-2 text-sm text-gray-500">
            <Sparkles className="w-4 h-4" />
            <span>Pro tip: You can always create more press releases from the sidebar</span>
          </div>
        </div>
      </div>
    </div>
  );
}