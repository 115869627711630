import { Newspaper, ArrowRight, Layout, Image, ChevronRight, Twitter, Facebook, Linkedin, Mail, Check, Sparkles, } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import AuthModal from './AuthModal';
import { useState, useEffect } from 'react';
import { logAnalyticsEvent } from '../lib/firebase';
import VideoModal from './VideoModal';

interface LandingPageProps {
  onStart: () => void;
}

interface PricingTier {
  name: string;
  price: number;
  interval?: string;
  features: string[];
  priceId?: string;
  highlight?: boolean;
  buttonText: string;
  description: string;
  limit?: number;
  disabled?: boolean;
  badge?: string;
}

const pricingTiers: PricingTier[] = [
  {
    name: 'Free',
    price: 0,
    features: [
      '2 Press Releases',
      'Basic Templates',
      'PDF Export',
      'Share Links',
    ],
    buttonText: 'Get Started',
    description: 'Perfect for getting started',
    limit: 2,
  },
  {
    name: 'Pro',
    price: 5,
    interval: 'month',
    features: [
      '50 Press Releases',
      'Premium Templates',
      'PDF Export',
      'Share Links',
      'Priority Support',
    ],
    highlight: true,
    buttonText: 'Upgrade to Pro',
    description: 'For serious professionals',
    limit: 50,
    priceId: 'pro'
  },
];

const scrollToFeedback = () => {
  logAnalyticsEvent('feedback_section_click');
  const feedbackSection = document.getElementById('feedback');
  feedbackSection?.scrollIntoView({ behavior: 'smooth' });
};

export default function LandingPage({ onStart }: LandingPageProps) {
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [selectedPriceId, setSelectedPriceId] = useState<string | null>(null);
  const { user } = useAuth();
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  console.log('LandingPage render:', { user, isAuthModalOpen });

  useEffect(() => {
    if (user) {
      console.log('User detected in LandingPage, calling onStart');
      onStart();
    }
  }, [user, onStart]);

  useEffect(() => {
    // Log page view
    logAnalyticsEvent('page_view', {
      page_title: 'Landing Page',
      page_location: window.location.href,
    });
  }, []);

  const scrollToPricing = () => {
    logAnalyticsEvent('pricing_section_click');
    const pricingSection = document.getElementById('pricing');
    pricingSection?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSubscribe = (priceId?: string) => {
    logAnalyticsEvent('subscribe_click', {
      plan: priceId || 'free',
      user_status: user ? 'logged_in' : 'guest',
    });

    if (!user && priceId) {
      setSelectedPriceId(priceId);
      setIsAuthModalOpen(true);
      return;
    }

    redirectToStripe(priceId);
  };

  const redirectToStripe = (priceId?: string) => {
    if (!priceId) {
      onStart();
      return;
    }

    if (priceId === 'lifetime') {
      const checkoutUrl = new URL('https://buy.stripe.com/7sI9Eg8lH9PH4xi8wx');
      checkoutUrl.searchParams.append('client_reference_id', user?.uid || '');
      checkoutUrl.searchParams.append('plan_type', 'lifetime');
      checkoutUrl.searchParams.append('prefilled_email', user?.email || '');
      window.location.href = checkoutUrl.toString();
      return;
    }

    if (user?.subscriptionStatus === 'pro') {
      onStart();
      return;
    }

    const checkoutUrl = new URL('https://buy.stripe.com/00g2bO1Xje5Xgg0eUU');
    checkoutUrl.searchParams.append('client_reference_id', user?.uid || '');
    checkoutUrl.searchParams.append('plan_type', 'pro');
    checkoutUrl.searchParams.append('prefilled_email', user?.email || '');
    window.location.href = checkoutUrl.toString();
  };

  useEffect(() => {
    if (user && selectedPriceId) {
      redirectToStripe(selectedPriceId);
      setSelectedPriceId(null);
    }
  }, [user, selectedPriceId]);

  const handleGetStarted = () => {
    logAnalyticsEvent('get_started_click', {
      user_status: user ? 'logged_in' : 'guest',
    });

    if (!user) {
      setIsAuthModalOpen(true);
    } else {
      onStart();
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 via-indigo-50 to-purple-50">
      {/* Header */}
      <header className="fixed top-0 left-0 right-0 z-30 bg-white/70 backdrop-blur-md border-b border-gray-200/50">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex items-center justify-between h-14">
            <div className="flex items-center">
              <Newspaper className="w-8 h-8 bg-gradient-to-r from-blue-600 to-indigo-600 text-white p-1.5 rounded-lg" />
              <h1 className="ml-2 text-xl font-bold bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent">Releaseify</h1>
            </div>
            <div className="flex items-center gap-4">
              <button
                onClick={scrollToFeedback}
                className="hidden md:flex items-center px-4 py-2 text-sm font-medium text-indigo-600 hover:text-indigo-700 bg-indigo-50 hover:bg-indigo-100 rounded-lg transition-colors border border-indigo-100 hover:border-indigo-200"
              >
                Feedback
              </button>
              <button
                onClick={scrollToPricing}
                className="hidden md:flex items-center px-4 py-2 text-sm font-medium text-indigo-600 hover:text-indigo-700 bg-indigo-50 hover:bg-indigo-100 rounded-lg transition-colors border border-indigo-100 hover:border-indigo-200"
              >
                View Pricing
              </button>
              <button
                onClick={handleGetStarted}
                className="flex items-center gap-2 px-4 md:px-6 py-2 md:py-3 text-sm font-medium text-white bg-gradient-to-r from-indigo-500 to-blue-500 rounded-lg hover:from-indigo-600 hover:to-blue-600 transition-all shadow-sm hover:shadow"
              >
                {user ? 'Continue' : 'Sign In'}
                <ArrowRight className="w-4 h-4" />
              </button>
            </div>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main className="relative pt-24 md:pt-32">
        <div className="max-w-7xl mx-auto px-4">
          {/* Hero Section */}
          <div className="relative z-10">
            <div className="grid lg:grid-cols-2 gap-12 items-center">
              {/* Left Column - Content */}
              <div className="text-center lg:text-left">
                <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-gray-900 mb-6">
                  Create and Share Press Releases in Minutes
                </h1>
                <p className="text-lg md:text-xl text-gray-600 mb-8 max-w-xl mx-auto lg:mx-0">
                  Create and share stunning press releases in minutes with Releaseify! Easily incorporate images, videos, charts, YouTube links, social media posts, and more to craft eye-catching, media-rich press releases that demand attention.


                </p>

                <div className="flex flex-col sm:flex-row gap-4 justify-center lg:justify-start">
                  <button
                    onClick={handleGetStarted}
                    className="flex items-center justify-center gap-2 px-8 py-4 text-base font-medium text-white bg-gradient-to-r from-indigo-500 to-blue-500 rounded-xl hover:from-indigo-600 hover:to-blue-600 transition-all shadow-lg hover:shadow-xl"
                  >
                    Start Creating
                    <ArrowRight className="w-5 h-5" />
                  </button>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsVideoModalOpen(true);
                    }}
                    className="flex items-center justify-center gap-2 px-8 py-4 text-base font-medium text-gray-700 bg-white rounded-xl hover:bg-gray-50 transition-all border border-gray-200 shadow-sm hover:shadow"
                  >
                    Watch Demo
                    <ChevronRight className="w-5 h-5" />
                  </a>
                </div>
              </div>

              {/* Right Column - Illustration */}
              <div className="relative lg:h-[600px] flex items-center justify-center mt-8 lg:mt-0">
                <div className="absolute inset-0 bg-gradient-to-r from-blue-100/20 to-indigo-100/20 rounded-3xl transform rotate-3"></div>
                <div className="absolute inset-0 bg-gradient-to-l from-blue-100/20 to-indigo-100/20 rounded-3xl transform -rotate-3"></div>
                <div className="relative bg-white/50 backdrop-blur-sm rounded-3xl p-8 shadow-xl border border-blue-100/50 w-full max-w-lg">
                  <div className="space-y-6">
                    <div className="flex items-center gap-4 border-b border-gray-200/50 pb-6">
                      <div className="w-12 h-12 bg-blue-100 rounded-xl flex items-center justify-center">
                        <Newspaper className="w-6 h-6 text-blue-600" />
                      </div>
                      <div className="flex-1">
                        <div className="h-4 bg-gray-200/60 rounded w-3/4"></div>
                        <div className="h-3 bg-gray-200/60 rounded w-1/2 mt-2"></div>
                      </div>
                    </div>
                    <div className="space-y-3">
                      <div className="h-3 bg-gray-200/60 rounded w-full"></div>
                      <div className="h-3 bg-gray-200/60 rounded w-5/6"></div>
                      <div className="h-3 bg-gray-200/60 rounded w-4/6"></div>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      <div className="h-24 bg-gray-200/60 rounded-lg"></div>
                      <div className="h-24 bg-gray-200/60 rounded-lg"></div>
                    </div>
                    <div className="space-y-3">
                      <div className="h-3 bg-gray-200/60 rounded w-5/6"></div>
                      <div className="h-3 bg-gray-200/60 rounded w-4/6"></div>
                    </div>
                  </div>

                  {/* Floating Elements */}
                  <div className="absolute -right-8 -top-8 w-16 h-16 bg-gradient-to-br from-blue-500 to-indigo-500 rounded-xl shadow-lg transform rotate-12 flex items-center justify-center text-white">
                    <Layout className="w-8 h-8" />
                  </div>
                  <div className="absolute -left-6 bottom-12 w-12 h-12 bg-gradient-to-br from-indigo-500 to-purple-500 rounded-xl shadow-lg transform -rotate-12 flex items-center justify-center text-white">
                    <Image className="w-6 h-6" />
                  </div>
                  <div className="absolute -right-4 -bottom-4 w-14 h-14 bg-gradient-to-br from-blue-400 to-indigo-400 rounded-xl shadow-lg transform rotate-45 flex items-center justify-center text-white">
                    <Sparkles className="w-7 h-7" />
                  </div>
                </div>
              </div>
            </div>

            {/* Feature Grid */}
            <div className="mt-24 grid grid-cols-1 md:grid-cols-3 gap-8 max-w-4xl mx-auto">
              <div className="group bg-white/50 backdrop-blur-sm rounded-xl p-8 border border-blue-100/50 hover:bg-white/80 transition-all duration-300 hover:shadow-xl hover:-translate-y-1 cursor-pointer">
                <div className="w-16 h-16 bg-gradient-to-br from-blue-500 to-indigo-500 rounded-xl flex items-center justify-center text-white mb-6 group-hover:scale-110 transition-transform duration-300">
                  <Layout className="w-8 h-8" />
                </div>
                <div className="text-xl font-semibold text-gray-900">Modern Editor</div>
                <div className="text-base text-gray-600 mt-2">Drag & Drop Interface</div>
              </div>

              <div className="group bg-white/50 backdrop-blur-sm rounded-xl p-8 border border-blue-100/50 hover:bg-white/80 transition-all duration-300 hover:shadow-xl hover:-translate-y-1 cursor-pointer">
                <div className="w-16 h-16 bg-gradient-to-br from-blue-500 to-indigo-500 rounded-xl flex items-center justify-center text-white mb-6 group-hover:scale-110 transition-transform duration-300">
                  <Image className="w-8 h-8" />
                </div>
                <div className="text-xl font-semibold text-gray-900">Rich Media</div>
                <div className="text-base text-gray-600 mt-2">Images & Videos</div>
              </div>

              <div className="group bg-white/50 backdrop-blur-sm rounded-xl p-8 border border-blue-100/50 hover:bg-white/80 transition-all duration-300 hover:shadow-xl hover:-translate-y-1 cursor-pointer">
                <div className="w-16 h-16 bg-gradient-to-br from-blue-500 to-indigo-500 rounded-xl flex items-center justify-center text-white mb-6 group-hover:scale-110 transition-transform duration-300">
                  <Sparkles className="w-8 h-8" />
                </div>
                <div className="text-xl font-semibold text-gray-900">Free to Start</div>
                <div className="text-base text-gray-600 mt-2">No Credit Card Required</div>
              </div>
            </div>
          </div>

          {/* Feedback Section */}
          <section id="feedback" className="py-16 relative">
            <div className="max-w-3xl mx-auto px-4">
              <div className="bg-gradient-to-r from-blue-50 to-indigo-50 border border-blue-100 rounded-2xl p-6 md:p-8 relative overflow-hidden">
                <div className="absolute top-0 right-0 w-64 h-64 bg-gradient-to-br from-blue-400/10 to-indigo-400/10 rounded-full transform translate-x-1/2 -translate-y-1/2" />
                
                <div className="relative">
                  <div className="inline-flex items-center gap-2 px-3 py-1 bg-blue-100 text-blue-600 rounded-full text-sm font-medium mb-4">
                    <span className="relative flex h-2 w-2">
                      <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75"></span>
                      <span className="relative inline-flex rounded-full h-2 w-2 bg-blue-500"></span>
                    </span>
                    Just Launched
                  </div>
                  
                  <h2 className="text-2xl font-bold text-gray-900 mb-3">
                    Help Us Shape the Future of Press Releases
                  </h2>
                  
                  <p className="text-gray-600 mb-6">
                    As a newly launched product, we're committed to building features that truly matter to you. Share your thoughts and help us create the perfect press release platform for your needs.
                  </p>
                  
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSfVyQoIXW1NwABwT2RT-ovdrDAqTGtDCC1OpygA7b4C5-pPAQ/viewform?usp=sf_link"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center gap-2 px-6 py-3 text-sm font-medium text-white bg-gradient-to-r from-blue-500 to-indigo-500 rounded-lg hover:from-blue-600 hover:to-indigo-600 transition-all shadow-sm hover:shadow"
                  >
                    Take the Survey
                    <ArrowRight className="w-4 h-4" />
                  </a>
                </div>
              </div>
            </div>
          </section>

          {/* Pricing Section */}
          <section id="pricing" className="py-16 md:py-24 relative">
            <div className="max-w-7xl mx-auto px-4">
              <div className="text-center mb-12 md:mb-16">
                <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">Simple, Transparent Pricing</h2>
                <p className="text-lg md:text-xl text-gray-600">Choose the plan that's right for you</p>
              </div>

              <div className="flex flex-col lg:flex-row gap-8 justify-center items-stretch max-w-7xl mx-auto">
                {pricingTiers.map((tier) => (
                  <div
                    key={tier.name}
                    className={`relative rounded-2xl bg-white border flex-1 min-w-[280px] max-w-[400px] mx-auto ${
                      tier.highlight
                        ? 'border-indigo-500 shadow-xl lg:scale-105'
                        : 'border-gray-200 shadow-lg'
                    } p-8`}
                  >
                    {tier.highlight && (
                      <div className="absolute -top-5 left-0 right-0 mx-auto w-40 text-center">
                        <div className="bg-gradient-to-r from-indigo-500 to-blue-500 text-white text-sm font-semibold px-4 py-1 rounded-full shadow-md">
                          {tier.badge || 'Most Popular'}
                        </div>
                      </div>
                    )}

                    <div className="mb-6">
                      <h3 className="text-2xl font-bold text-gray-900">{tier.name}</h3>
                      <p className="text-gray-600 mt-2">{tier.description}</p>
                    </div>

                    <div className="mb-8">
                      <div className="flex items-end gap-2">
                        <div className="text-4xl font-bold text-gray-900">
                          ${tier.price}
                        </div>
                        {tier.interval && (
                          <div className="text-gray-600 mb-1">/{tier.interval}</div>
                        )}
                        {tier.name === 'Lifetime Access' && (
                          <div className="text-gray-600 mb-1">one-time</div>
                        )}
                      </div>
                    </div>

                    <ul className="space-y-4 mb-8">
                      {tier.features.map((feature) => (
                        <li key={feature} className="flex items-start gap-3">
                          <div className="flex-shrink-0 w-5 h-5 rounded-full bg-green-100 flex items-center justify-center">
                            <Check className="w-3 h-3 text-green-600" />
                          </div>
                          <span className="text-gray-600">{feature}</span>
                        </li>
                      ))}
                    </ul>

                    <button
                      onClick={() => handleSubscribe(tier.priceId)}
                      className={`w-full py-3 px-4 rounded-xl font-medium transition-all ${
                        tier.highlight
                          ? 'bg-gradient-to-r from-indigo-500 to-blue-500 text-white hover:from-indigo-600 hover:to-blue-600 shadow-md hover:shadow-lg'
                          : tier.disabled
                          ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                          : tier.name === 'Enterprise'
                          ? 'bg-gray-900 text-white hover:bg-gray-800'
                          : 'bg-white text-gray-900 border border-gray-200 hover:border-gray-300 hover:bg-gray-50'
                      }`}
                      disabled={tier.disabled}
                    >
                      {tier.buttonText}
                    </button>

                    {tier.disabled && (
                      <div className="absolute -top-5 left-0 right-0 mx-auto w-32 text-center">
                        <div className="bg-gray-500 text-white text-sm font-semibold px-4 py-1 rounded-full shadow-md">
                          Coming Soon
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>

              <div className="mt-16 text-center text-gray-600">
                <p className="text-sm">
                  All plans include our core features. Upgrade or downgrade at any time.
                </p>
              </div>
            </div>
          </section>
        </div>
      </main>

      {/* Footer */}
      <footer className="relative z-10 border-t border-gray-200/80 bg-white/50 backdrop-blur-sm mt-16 md:mt-32">
        <div className="max-w-7xl mx-auto px-4 py-8 md:py-12">
          <div className="flex flex-col md:flex-row justify-between items-center gap-6 md:gap-8">
            <div className="flex flex-col items-center md:items-start gap-4">
              <div className="flex items-center">
                <Newspaper className="w-8 h-8 bg-gradient-to-r from-blue-600 to-indigo-600 text-white p-1.5 rounded-lg" />
                <h2 className="ml-2 text-xl font-bold bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent">Releaseify</h2>
              </div>
              <p className="text-sm text-gray-600 text-center md:text-left">
                Modern press release creation platform for the digital age.
              </p>
            </div>

            <div className="flex items-center gap-6">
              <a href="#" className="text-gray-400 hover:text-gray-600 transition-colors">
                <Twitter className="w-5 h-5" />
              </a>
              <a href="#" className="text-gray-400 hover:text-gray-600 transition-colors">
                <Facebook className="w-5 h-5" />
              </a>
              <a href="#" className="text-gray-400 hover:text-gray-600 transition-colors">
                <Linkedin className="w-5 h-5" />
              </a>
              <a href="mailto:commsly.contact@gmail.com" className="text-gray-400 hover:text-gray-600 transition-colors">
                <Mail className="w-5 h-5" />
              </a>
            </div>
          </div>

          <div className="mt-8 pt-8 border-t border-gray-200/80">
            <div className="flex flex-col md:flex-row justify-between items-center gap-4">
              <div className="text-sm text-gray-600">
                © {new Date().getFullYear()} Releaseify. All rights reserved.
              </div>
              <div className="flex items-center gap-6 text-sm">
                <a href="#" className="text-gray-600 hover:text-gray-900">Privacy</a>
                <a href="#" className="text-gray-600 hover:text-gray-900">Terms</a>
                <a href="#" className="text-gray-600 hover:text-gray-900">Cookies</a>
                <a 
                  href="mailto:commsly.contact@gmail.com?subject=Bug%20Report%20-%20Releaseify&body=Please%20describe%20the%20bug%20you%20encountered:"
                  className="text-red-500 hover:text-red-700"
                >
                  Report Bug
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <AuthModal 
        isOpen={isAuthModalOpen} 
        onClose={() => {
          setIsAuthModalOpen(false);
          setSelectedPriceId(null);
        }} 
        onStart={onStart} 
      />

      <VideoModal
        isOpen={isVideoModalOpen}
        onClose={() => setIsVideoModalOpen(false)}
        videoId="r5K1E1z0_jw"
      />
    </div>
  );
}