import { db } from '../lib/firebase';
import { collection, addDoc, updateDoc, doc, getDocs, query, where, serverTimestamp, deleteDoc } from 'firebase/firestore';
import { PressRelease } from '../types';

export const pressReleaseService = {
  async getUserReleases(userId: string): Promise<PressRelease[]> {
    const releasesRef = collection(db, 'pressReleases');
    const q = query(releasesRef, where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    
    return querySnapshot.docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
      date: doc.data().date?.toDate(),
      lastModified: doc.data().lastModified?.toDate(),
    })) as PressRelease[];
  },

  async createRelease(release: Omit<PressRelease, 'id'>, userId: string): Promise<PressRelease> {
    try {
      const releasesRef = collection(db, 'pressReleases');
      const releaseData = {
        ...release,
        userId,
        date: serverTimestamp(),
        lastModified: serverTimestamp(),
      };
      
      const docRef = await addDoc(releasesRef, releaseData);
      return {
        ...releaseData,
        id: docRef.id,
        date: new Date(),
        lastModified: new Date(),
      } as PressRelease;
    } catch (error: any) {
      if (error.code === 'permission-denied') {
        throw new Error('You have reached the maximum number of press releases for the free tier. Please upgrade to create more.');
      }
      throw error;
    }
  },

  async updateRelease(id: string, data: Partial<PressRelease>): Promise<void> {
    const releaseRef = doc(db, 'pressReleases', id);
    await updateDoc(releaseRef, {
      ...data,
      lastModified: serverTimestamp(),
    });
  },

  async deleteRelease(id: string): Promise<void> {
    const releaseRef = doc(db, 'pressReleases', id);
    await deleteDoc(releaseRef);
  },
}; 