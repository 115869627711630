import { Template } from '../types';

export const pressReleaseTemplates: Template[] = [
  {
    id: 'earnings-announcement',
    title: 'Earnings Announcement',
    description: 'Professional template for quarterly earnings releases',
    blocks: [
      {
        id: crypto.randomUUID(),
        type: 'contact',
        content: 'Contact: Sarah Chen\nTech Innovations Inc.\nPhone: (415) 555-0123\nFax: (415) 555-0124\nwww.techinnovations.com'
      },
      {
        id: crypto.randomUUID(),
        type: 'headline',
        content: 'FOR IMMEDIATE RELEASE:'
      },
      {
        id: crypto.randomUUID(),
        type: 'headline',
        content: 'Tech Innovations Reports Record Q4 2023 Earnings'
      },
      {
        id: crypto.randomUUID(),
        type: 'subheading',
        content: 'Revenue growth exceeds market expectations with strong performance across all segments'
      },
      {
        id: crypto.randomUUID(),
        type: 'body',
        content: 'San Francisco, January 15, 2024: Tech Innovations Inc. today announced record revenue of $892 million for the fourth quarter of fiscal year 2023, representing a 45% increase compared to $615 million in the same period last year. Net income reached $156 million, or $2.34 per diluted share, compared to $98 million, or $1.47 per share, in Q4 2022.'
      },
      {
        id: crypto.randomUUID(),
        type: 'quote',
        content: '"Our outstanding fourth quarter results cap off a transformational year for Tech Innovations," said Jennifer Wong, CEO of Tech Innovations. "The successful launch of our AI-powered enterprise solutions and expanded cloud services portfolio has positioned us for sustained growth in 2024."'
      },
      {
        id: crypto.randomUUID(),
        type: 'body',
        content: 'The company\'s enterprise solutions division reported revenue of $445 million, up 52% year-over-year, while cloud services grew 38% to $447 million.'
      },
      {
        id: crypto.randomUUID(),
        type: 'footer',
        content: '###\n\nAbout Tech Innovations Inc.\nTech Innovations Inc. is a leading provider of enterprise software solutions and cloud services. With over 5,000 employees worldwide, we help businesses transform their digital operations through cutting-edge AI and cloud technologies.\n\nFor more information, visit www.techinnovations.com or follow us on social media.'
      }
    ]
  },
  {
    id: 'media-statement',
    title: 'Media Statement',
    description: 'Respond to media inquiries or address public concerns',
    blocks: [
      {
        id: crypto.randomUUID(),
        type: 'contact',
        content: 'Contact: David Anderson\nMedia Relations Director\nPhone: (212) 555-0189\nEmail: media@company.com\nwww.company.com'
      },
      {
        id: crypto.randomUUID(),
        type: 'headline',
        content: 'FOR IMMEDIATE RELEASE:'
      },
      {
        id: crypto.randomUUID(),
        type: 'headline',
        content: 'Company Statement Regarding Recent Market Reports'
      },
      {
        id: crypto.randomUUID(),
        type: 'subheading',
        content: 'Setting the record straight on industry developments'
      },
      {
        id: crypto.randomUUID(),
        type: 'body',
        content: 'New York, [Current Date]: In response to recent media reports regarding our company\'s market position and strategic direction, we would like to provide clarity on several key points.'
      },
      {
        id: crypto.randomUUID(),
        type: 'quote',
        content: '"We remain fully committed to our core mission of delivering value to our customers and shareholders," said Sarah Chen, CEO. "Recent market speculation does not accurately reflect our company\'s strong market position and growth trajectory."'
      },
      {
        id: crypto.randomUUID(),
        type: 'body',
        content: 'Key points of clarification:\n\n- Our company maintains a strong financial position with significant cash reserves\n- We continue to invest in innovation and product development\n- Our customer base has grown 45% year-over-year\n- We are actively expanding our presence in key markets'
      },
      {
        id: crypto.randomUUID(),
        type: 'quote',
        content: '"We appreciate the ongoing support of our customers, partners, and employees during this time," added Chen. "We remain focused on executing our strategic vision and delivering exceptional value to all stakeholders."'
      },
      {
        id: crypto.randomUUID(),
        type: 'footer',
        content: '###\n\nAbout Company Name\nCompany Name is an industry leader in [industry] solutions, serving a global customer base. We are committed to innovation, transparency, and creating sustainable value for all stakeholders.\n\nFor additional information or media inquiries, please contact media@company.com'
      }
    ]
  },
  {
    id: 'product-launch',
    title: 'Product Launch',
    description: 'Announce new product releases',
    blocks: [
      {
        id: crypto.randomUUID(),
        type: 'contact',
        content: 'Contact: Emily Martinez\nFuture Mobility Inc.\nPhone: (650) 555-0177\nFax: (650) 555-0178\nwww.futuremobility.com'
      },
      {
        id: crypto.randomUUID(),
        type: 'headline',
        content: 'FOR IMMEDIATE RELEASE:'
      },
      {
        id: crypto.randomUUID(),
        type: 'headline',
        content: 'Future Mobility Unveils Revolutionary Electric Vehicle Platform'
      },
      {
        id: crypto.randomUUID(),
        type: 'subheading',
        content: 'Next-generation EV platform promises 500-mile range and 15-minute charging'
      },
      {
        id: crypto.randomUUID(),
        type: 'body',
        content: 'Palo Alto, January 20, 2024: Future Mobility Inc. (NASDAQ: FMI) today unveiled its next-generation electric vehicle platform, the FM-X, featuring breakthrough battery technology and advanced autonomous driving capabilities. The new platform achieves an industry-leading 500-mile range and can charge from 10% to 80% in just 15 minutes.'
      },
      {
        id: crypto.randomUUID(),
        type: 'quote',
        content: '"The FM-X platform represents a quantum leap in electric vehicle technology," said David Park, CTO of Future Mobility. "Our innovative battery architecture and thermal management system set new benchmarks for range, charging speed, and performance."'
      },
      {
        id: crypto.randomUUID(),
        type: 'footer',
        content: '###\n\nAbout Future Mobility Inc.\nFuture Mobility Inc. (NASDAQ: FMI) is revolutionizing transportation through innovative electric vehicle technology. Our mission is to accelerate the world\'s transition to sustainable transportation through advanced battery technology and autonomous driving solutions.\n\nFor media inquiries, please contact press@futuremobility.com'
      }
    ]
  },
  {
    id: 'company-news',
    title: 'Company News',
    description: 'Share company updates and milestones',
    blocks: [
      {
        id: crypto.randomUUID(),
        type: 'contact',
        content: 'Contact: Robert Chen\nGlobal Communications\nPhone: (415) 555-0199\nEmail: press@company.com\nwww.company.com'
      },
      {
        id: crypto.randomUUID(),
        type: 'headline',
        content: 'FOR IMMEDIATE RELEASE:'
      },
      {
        id: crypto.randomUUID(),
        type: 'headline',
        content: 'Company Name Expands Operations with New European Headquarters'
      },
      {
        id: crypto.randomUUID(),
        type: 'subheading',
        content: 'Strategic expansion marks significant milestone in company\'s global growth'
      },
      {
        id: crypto.randomUUID(),
        type: 'body',
        content: 'London, March 15, 2024: Company Name today announced the opening of its new European headquarters in London, marking a significant milestone in the company\'s international expansion strategy. The new office will serve as a hub for operations across Europe, supporting the company\'s growing customer base in the region.'
      },
      {
        id: crypto.randomUUID(),
        type: 'quote',
        content: '"This expansion represents a pivotal moment in our company\'s history," said Jane Smith, CEO of Company Name. "Our new European headquarters will enable us to better serve our customers across the region while creating new opportunities for growth and innovation."'
      },
      {
        id: crypto.randomUUID(),
        type: 'body',
        content: 'The new office, located in the heart of London\'s tech district, will initially house 100 employees across sales, customer success, and technical operations. The company plans to double its European workforce by the end of 2024.'
      },
      {
        id: crypto.randomUUID(),
        type: 'body',
        content: 'This expansion follows a year of exceptional growth for Company Name, with European revenue increasing by 150% in 2023. The company\'s European customer base now includes over 500 enterprise clients across 15 countries.'
      },
      {
        id: crypto.randomUUID(),
        type: 'footer',
        content: '###\n\nAbout Company Name\nCompany Name is a leading provider of enterprise solutions, serving over 2,000 customers worldwide. Founded in 2015, the company has experienced rapid growth and now operates across North America, Europe, and Asia Pacific.\n\nFor more information, visit www.company.com or contact press@company.com'
      }
    ]
  },
  {
    id: 'event-promotion',
    title: 'Promoting Event',
    description: 'Announce and promote upcoming events',
    blocks: [
      {
        id: crypto.randomUUID(),
        type: 'contact',
        content: 'Contact: Sarah Williams\nEvent Communications Team\nPhone: (212) 555-0177\nEmail: events@company.com\nwww.eventwebsite.com'
      },
      {
        id: crypto.randomUUID(),
        type: 'headline',
        content: 'FOR IMMEDIATE RELEASE:'
      },
      {
        id: crypto.randomUUID(),
        type: 'headline',
        content: 'Industry Leaders to Gather at Innovation Summit 2024'
      },
      {
        id: crypto.randomUUID(),
        type: 'subheading',
        content: 'Annual conference brings together technology pioneers and business leaders to shape the future of innovation'
      },
      {
        id: crypto.randomUUID(),
        type: 'body',
        content: 'San Francisco, April 10, 2024: The Innovation Summit 2024, the premier gathering of technology and business leaders, will take place from June 15-17, 2024, at the Moscone Center in San Francisco. This year\'s theme, "Shaping Tomorrow\'s Technology," will explore emerging trends in AI, sustainable technology, and digital transformation.'
      },
      {
        id: crypto.randomUUID(),
        type: 'quote',
        content: '"The Innovation Summit has become the must-attend event for anyone at the forefront of technological advancement," said Michael Johnson, Event Director. "This year\'s program features an unprecedented lineup of speakers and workshops that will inspire and educate attendees about the latest innovations shaping our future."'
      },
      {
        id: crypto.randomUUID(),
        type: 'body',
        content: 'Keynote speakers include:\n- Lisa Chen, CEO of TechCorp\n- Dr. James Wilson, AI Research Director at Future Labs\n- Sarah Martinez, Chief Innovation Officer at Global Innovations\n\nThe three-day event will feature:\n- 50+ expert-led sessions\n- Hands-on workshops\n- Networking opportunities\n- Innovation showcase\n- Startup pitch competition'
      },
      {
        id: crypto.randomUUID(),
        type: 'cta',
        content: 'Early bird registration is now open until May 1, 2024. For more information and to register, visit www.innovationsummit2024.com'
      },
      {
        id: crypto.randomUUID(),
        type: 'footer',
        content: '###\n\nAbout Innovation Summit\nThe Innovation Summit is the leading conference for technology innovation and digital transformation. Now in its 10th year, the event attracts over 5,000 attendees from 40+ countries, featuring world-class speakers and cutting-edge demonstrations of emerging technologies.\n\nFor media inquiries or press passes, please contact press@innovationsummit2024.com'
      }
    ]
  }
];