import React, { useState } from 'react';
import { Menu, PlusCircle, Clock, Trash2, AlertCircle, LogOut, Sparkles } from 'lucide-react';
import { PressRelease } from '../types';
import { format } from 'date-fns';
import { Timestamp } from 'firebase/firestore';
import PricingModal from './PricingModal';

interface SidebarProps {
  isOpen: boolean;
  onToggle: () => void;
  pressReleases: PressRelease[];
  currentReleaseId: string;
  onSelectRelease: (id: string) => void;
  onCreateNew: () => void;
  onDelete?: (id: string) => void;
  onSignOut: () => void;
  currentPlan: string;
  releasesCount: number;
}

interface DeleteConfirmationProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
}

const DeleteConfirmation = ({ isOpen, onClose, onConfirm, title }: DeleteConfirmationProps) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full mx-4">
        <div className="flex items-center gap-3 mb-4">
          <AlertCircle className="w-6 h-6 text-red-500" />
          <h3 className="text-lg font-semibold text-gray-900">Delete Press Release</h3>
        </div>
        <p className="text-gray-600 mb-6">
          Are you sure you want to delete "{title}"? This action cannot be undone.
        </p>
        <div className="flex justify-end gap-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 text-sm font-medium text-white bg-red-500 rounded-lg hover:bg-red-600 transition-colors"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

const formatDate = (dateValue: Date | Timestamp | any) => {
  try {
    if (dateValue instanceof Timestamp) {
      return format(dateValue.toDate(), 'MMM d, h:mm a');
    }
    if (dateValue instanceof Date) {
      return format(dateValue, 'MMM d, h:mm a');
    }
    if (typeof dateValue === 'string' || typeof dateValue === 'number') {
      return format(new Date(dateValue), 'MMM d, h:mm a');
    }
    return 'Invalid date';
  } catch (error) {
    console.error('Error formatting date:', dateValue);
    return 'Invalid date';
  }
};

export default function Sidebar({
  isOpen,
  onToggle,
  pressReleases,
  currentReleaseId,
  onSelectRelease,
  onCreateNew,
  onDelete,
  onSignOut,
  currentPlan,
  releasesCount,
}: SidebarProps) {
  const [deleteConfirmation, setDeleteConfirmation] = useState<{
    isOpen: boolean;
    releaseId: string;
    title: string;
  }>({
    isOpen: false,
    releaseId: '',
    title: '',
  });
  const [isPricingModalOpen, setIsPricingModalOpen] = useState(false);

  const handleDeleteClick = (e: React.MouseEvent, release: PressRelease) => {
    e.stopPropagation();
    setDeleteConfirmation({
      isOpen: true,
      releaseId: release.id,
      title: release.title,
    });
  };

  const handleDeleteConfirm = () => {
    if (onDelete) {
      onDelete(deleteConfirmation.releaseId);
    }
    setDeleteConfirmation({ isOpen: false, releaseId: '', title: '' });
  };

  return (
    <>
      <div
        className={`fixed left-0 bottom-0 bg-white border-r border-gray-200 transition-all duration-300 flex flex-col ${
          isOpen ? 'top-0 z-40' : 'top-16 z-20'
        } ${
          isOpen ? 'w-64' : 'w-16'
        }`}
      >
        {isOpen && <div className="h-16" />}
        <button
          onClick={onToggle}
          className={`w-full flex items-center justify-center p-4 hover:bg-gray-50 transition-colors ${
            !isOpen && 'relative bg-white'
          }`}
          aria-label={isOpen ? "Close sidebar" : "Open sidebar"}
        >
          <Menu className="w-6 h-6 text-gray-600" />
        </button>

        <div className="flex-1 p-4">
          <button
            onClick={onCreateNew}
            className="w-full flex items-center gap-2 px-3 py-2 text-sm font-medium text-white bg-gradient-to-r from-indigo-500 to-blue-500 rounded-lg hover:from-indigo-600 hover:to-blue-600 transition-all shadow-sm hover:shadow"
          >
            <PlusCircle className="w-4 h-4" />
            {isOpen && <span>New Press Release</span>}
          </button>

          {isOpen && (
            <div className="mt-6 space-y-1">
              <h3 className="px-3 text-xs font-medium text-gray-500 uppercase tracking-wider mb-2">
                Your Press Releases
              </h3>
              {pressReleases.map((release) => (
                <div
                  key={release.id}
                  className={`group flex items-center gap-2 p-2 rounded-md transition-colors ${
                    currentReleaseId === release.id
                      ? 'bg-blue-50'
                      : 'hover:bg-gray-50'
                  }`}
                >
                  <button
                    onClick={() => onSelectRelease(release.id)}
                    className="flex-1 flex items-start text-left"
                  >
                    <div className={`flex-1 ${
                      currentReleaseId === release.id
                        ? 'text-blue-600'
                        : 'text-gray-700'
                    }`}>
                      <div className="font-medium truncate">
                        {release.title}
                      </div>
                      <div className="flex items-center gap-1 text-xs text-gray-500 mt-1">
                        <Clock className="w-3 h-3" />
                        {formatDate(release.lastModified)}
                      </div>
                    </div>
                  </button>
                  {pressReleases.length > 1 && (
                    <button
                      onClick={(e) => handleDeleteClick(e, release)}
                      className="opacity-0 group-hover:opacity-100 p-1 text-gray-400 hover:text-red-500 transition-all rounded"
                    >
                      <Trash2 className="w-4 h-4" />
                    </button>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>

        {isOpen && (
          <>
            {currentPlan === 'free' && (
              <div className="px-4 mb-2">
                <button
                  onClick={() => setIsPricingModalOpen(true)}
                  className="w-full flex items-center justify-center gap-2 px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-indigo-400/90 to-blue-400/90 rounded-lg hover:from-indigo-500 hover:to-blue-500 transition-all shadow-sm hover:shadow"
                >
                  <Sparkles className="w-4 h-4" />
                  Upgrade to Pro
                </button>
              </div>
            )}

            <div className="p-4 border-t border-gray-200">
              <button
                onClick={onSignOut}
                className="w-full flex items-center justify-center gap-2 px-3 py-2 text-sm font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50 rounded-lg transition-colors"
              >
                <LogOut className="w-4 h-4" />
                <span>Home</span>
              </button>
            </div>
          </>
        )}

        {currentPlan === 'free' && (
          <PricingModal
            isOpen={isPricingModalOpen}
            onClose={() => setIsPricingModalOpen(false)}
            currentPlan={currentPlan}
            releasesCount={releasesCount}
          />
        )}
      </div>

      <DeleteConfirmation
        isOpen={deleteConfirmation.isOpen}
        onClose={() => setDeleteConfirmation({ isOpen: false, releaseId: '', title: '' })}
        onConfirm={handleDeleteConfirm}
        title={deleteConfirmation.title}
      />
    </>
  );
}