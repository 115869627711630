import { useState, useEffect, useCallback } from 'react';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import { BlockType, PressRelease, StyleType, Block, Template } from '../types';
import BlockToolbar from './BlockToolbar';
import EditableBlock from './EditableBlock';
import PreviewMode from './PreviewMode';
import Sidebar from './Sidebar';
import TemplateSelector from './TemplateSelector';
import { pressReleaseTemplates } from '../data/templates';
import { createShareableLink } from '../utils/share';
import ShareDialog from './ShareDialog';
import PricingModal from './PricingModal';
import { Eye, Edit2, Download, Share2, Layout, Sparkles } from 'lucide-react';
import { exportToPdf } from '../utils/pdf';
import toast from 'react-hot-toast';
import { pressReleaseService } from '../services/pressReleaseService';
import debounce from 'lodash/debounce';
import { useAuth } from '../contexts/AuthContext';

interface EditorProps {
  currentPlan: 'free' | 'pro';
  releasesCount: number;
  onCreateRelease: () => void;
  onSignOut: () => void;
}

const createNewRelease = (style: StyleType = 'modern'): Omit<PressRelease, 'userId'> => ({
  id: crypto.randomUUID(),
  title: 'Untitled Release',
  date: new Date(),
  blocks: [],
  lastModified: new Date(),
  style
});

export default function Editor({ currentPlan, releasesCount, onCreateRelease, onSignOut }: EditorProps) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
  const [isPricingModalOpen, setIsPricingModalOpen] = useState(false);
  const [pressReleases, setPressReleases] = useState<PressRelease[]>([]);
  const [currentRelease, setCurrentRelease] = useState<PressRelease | null>(null);
  const [shareUrl, setShareUrl] = useState<string>('');

  const { user } = useAuth();
  const userId = user?.uid;

  useEffect(() => {
    const initializeEditor = async () => {
      if (userId) {
        const releases = await pressReleaseService.getUserReleases(userId);
        setPressReleases(releases);
        
        if (releases.length === 0) {
          // If user has no releases, show template selector
          setIsTemplateModalOpen(true);
        } else {
          // If user has releases, set the first one as current
          setCurrentRelease(releases[0]);
        }
      }
    };

    initializeEditor();
  }, [userId]);

  const debouncedUpdateRelease = useCallback(
    debounce(async (updatedRelease: PressRelease) => {
      try {
        if (!updatedRelease.id) {
          console.error('Missing release ID');
          return;
        }

        const cleanBlocks = updatedRelease.blocks.map(block => ({
          id: block.id,
          type: block.type,
          content: block.content,
          ...(block.mediaUrl ? { mediaUrl: block.mediaUrl } : {})
        }));

        const releaseData = {
          title: updatedRelease.title,
          blocks: cleanBlocks,
          style: updatedRelease.style,
          lastModified: updatedRelease.lastModified,
          date: updatedRelease.date
        };

        await pressReleaseService.updateRelease(updatedRelease.id, releaseData);
      } catch (error) {
        console.error('Failed to update press release:', error);
        toast.error(error instanceof Error ? error.message : 'Failed to save changes. Please try again.');
      }
    }, 1000),
    []
  );

  const updateCurrentRelease = (updates: Partial<PressRelease>) => {
    if (!currentRelease) return;
    
    try {
      const updatedRelease = {
        ...currentRelease,
        ...updates,
        lastModified: new Date()
      };
      
      setCurrentRelease(updatedRelease);
      setPressReleases(prev => prev.map(release => 
        release.id === currentRelease.id ? updatedRelease : release
      ));
      
      debouncedUpdateRelease(updatedRelease);
    } catch (error) {
      console.error('Error in updateCurrentRelease:', error);
      toast.error('Failed to update release. Please try again.');
    }
  };

  useEffect(() => {
    return () => {
      debouncedUpdateRelease.cancel();
    };
  }, [debouncedUpdateRelease]);

  const handleStyleChange = (style: StyleType) => {
    if (currentRelease) {
      updateCurrentRelease({ style });
    }
  };

  const addBlock = (type: BlockType) => {
    if (!currentRelease) return;
    updateCurrentRelease({
      blocks: [
        {
          id: crypto.randomUUID(),
          type,
          content: '',
        },
        ...currentRelease.blocks,
      ],
    });
  };

  const updateBlock = (id: string, content: string, mediaUrl?: string) => {
    if (!currentRelease) return;
    
    const existingBlock = currentRelease.blocks.find(block => block.id === id);
    if (!existingBlock) return;

    const updatedBlock: Block = {
      id: existingBlock.id,
      type: existingBlock.type,
      content,
      ...(mediaUrl ? { mediaUrl } : {}),
      ...(existingBlock.chartData ? { chartData: existingBlock.chartData } : {})
    };

    updateCurrentRelease({
      blocks: currentRelease.blocks.map(block =>
        block.id === id ? updatedBlock : block
      ),
    });
  };

  const removeBlock = (id: string) => {
    if (!currentRelease) return;
    updateCurrentRelease({
      blocks: currentRelease.blocks.filter(block => block.id !== id),
    });
  };

  const deleteRelease = async (id: string) => {
    try {
      await pressReleaseService.deleteRelease(id);
      setPressReleases(prev => prev.filter(release => release.id !== id));
      if (currentRelease?.id === id) {
        const remaining = pressReleases.filter(release => release.id !== id);
        setCurrentRelease(remaining[0] || null);
      }
      toast.success('Press release deleted successfully');
    } catch (error) {
      console.error('Failed to delete press release:', error);
      toast.error('Failed to delete press release');
    }
  };

  const onDragEnd = (result: any) => {
    if (!result.destination || !currentRelease) return;

    const items = Array.from(currentRelease.blocks);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    updateCurrentRelease({ blocks: items });
  };

  const handleExportPdf = async () => {
    if (!currentRelease) return;
    const success = await exportToPdf(currentRelease);
    if (success) {
      toast.success('PDF exported successfully');
    } else {
      toast.error('Failed to export PDF');
    }
  };

  const handleShare = async () => {
    if (!currentRelease) return;
    try {
      const url = await createShareableLink(currentRelease);
      setShareUrl(url);
      setIsShareDialogOpen(true);
    } catch (error) {
      console.error('Failed to create share link:', error);
      toast.error('Failed to create share link');
    }
  };

  const handleSelectRelease = (id: string) => {
    const release = pressReleases.find(r => r.id === id);
    if (release) {
      setCurrentRelease(release);
    }
  };

  const handleSelectTemplate = async (template: Template, style: StyleType) => {
    if (!userId) {
      toast.error('User not authenticated');
      return;
    }

    const newRelease = {
      ...createNewRelease(style),
      userId,
      blocks: template.blocks.map((block: Block) => ({
        ...block,
        id: crypto.randomUUID(),
      })),
    };

    try {
      const savedRelease = await pressReleaseService.createRelease(newRelease, userId);
      
      setPressReleases(prev => [...prev, savedRelease]);
      setCurrentRelease(savedRelease);
      setIsTemplateModalOpen(false);
      onCreateRelease();
    } catch (error) {
      toast.error('Failed to create release from template');
      console.error(error);
    }
  };

  const handleCreateNew = () => {
    if (currentPlan === 'free' && pressReleases.length >= 2) {
      setIsPricingModalOpen(true);
      return;
    }
    
    setIsTemplateModalOpen(true);
  };

  if (isTemplateModalOpen) {
    return (
      <TemplateSelector
        isOpen={isTemplateModalOpen}
        onClose={async () => {
          if (!userId) {
            toast.error('User not authenticated');
            return;
          }

          const newRelease = {
            ...createNewRelease(),
            userId
          };

          try {
            const savedRelease = await pressReleaseService.createRelease(newRelease, userId);
            setPressReleases(prev => [...prev, savedRelease]);
            setCurrentRelease(savedRelease);
            setIsTemplateModalOpen(false);
            onCreateRelease();
          } catch (error) {
            toast.error('Failed to create new release');
            console.error(error);
          }
        }}
        onSelectTemplate={handleSelectTemplate}
        templates={pressReleaseTemplates}
        currentPlan={currentPlan}
        onUpgrade={() => setIsPricingModalOpen(true)}
        userId={userId || ''}
        user={user}
      />
    );
  }

  if (!currentRelease) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-500" />
      </div>
    );
  }

  return (
    <>
      <Sidebar
        isOpen={isSidebarOpen}
        onToggle={() => setIsSidebarOpen(!isSidebarOpen)}
        pressReleases={pressReleases}
        currentReleaseId={currentRelease.id}
        onSelectRelease={handleSelectRelease}
        onCreateNew={handleCreateNew}
        onDelete={deleteRelease}
        onSignOut={onSignOut}
        currentPlan={currentPlan}
        releasesCount={releasesCount}
      />

      <div className={`transition-all duration-300 ${isSidebarOpen ? 'ml-64' : 'ml-16'}`}>
        <div className="fixed top-16 left-16 right-0 z-20 bg-white border-b border-gray-200 shadow-sm">
          <div className="max-w-4xl mx-auto px-6 py-3 flex items-center justify-between">
            <div className="flex items-center gap-4">
              <input
                type="text"
                value={currentRelease.title}
                onChange={(e) => updateCurrentRelease({ title: e.target.value })}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    (e.target as HTMLInputElement).blur();
                  }
                }}
                className="text-xl font-bold bg-transparent border-none focus:outline-none focus:ring-2 focus:ring-indigo-500 rounded-lg px-2 py-1"
                placeholder="Enter title..."
              />
              <div className="h-6 w-px bg-gray-200" />
              <div className="flex items-center gap-2">
                <button
                  onClick={() => handleStyleChange('classic')}
                  className={`flex items-center gap-1.5 px-3 py-1.5 text-sm font-medium rounded-lg transition-all ${
                    currentRelease.style === 'classic'
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-700 hover:bg-gray-100'
                  }`}
                >
                  <Layout className="w-4 h-4" />
                  Classic
                </button>
                <button
                  onClick={() => handleStyleChange('modern')}
                  className={`flex items-center gap-1.5 px-3 py-1.5 text-sm font-medium rounded-lg transition-all ${
                    currentRelease.style === 'modern'
                      ? 'bg-gradient-to-r from-indigo-500 to-blue-500 text-white'
                      : 'text-gray-700 hover:bg-gray-100'
                  }`}
                >
                  <Sparkles className="w-4 h-4" />
                  Modern
                </button>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <button
                onClick={handleExportPdf}
                className="flex items-center gap-2 px-3 py-1.5 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
              >
                <Download className="w-4 h-4" />
                Export PDF
              </button>
              <button
                onClick={handleShare}
                className="flex items-center gap-2 px-3 py-1.5 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
              >
                <Share2 className="w-4 h-4" />
                Share
              </button>
              <div className="h-6 w-px bg-gray-200" />
              <button
                onClick={() => setIsPreviewMode(!isPreviewMode)}
                className="flex items-center gap-2 px-3 py-1.5 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
              >
                {isPreviewMode ? (
                  <>
                    <Edit2 className="w-4 h-4" />
                    Edit
                  </>
                ) : (
                  <>
                    <Eye className="w-4 h-4" />
                    Preview
                  </>
                )}
              </button>
            </div>
          </div>
        </div>

        <main className="pt-28 pb-16 max-w-4xl mx-auto px-6">
          <div className={`bg-white rounded-lg shadow-sm border border-gray-200 min-h-[1100px] p-8 mx-auto ${
            currentRelease.style === 'classic' ? 'font-serif' : 'font-sans'
          }`}>
            {isPreviewMode ? (
              <PreviewMode 
                pressRelease={currentRelease} 
                onClose={() => setIsPreviewMode(false)}
              />
            ) : (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="blocks">
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className="space-y-4"
                    >
                      {currentRelease.blocks.length === 0 && (
                        <div className="text-center py-12">
                          <p className="text-lg text-gray-900/50">Your press release is empty</p>
                          <p className="text-sm text-gray-900/40">Use the toolbar to add content blocks</p>
                        </div>
                      )}
                      {currentRelease.blocks.map((block, index) => (
                        <EditableBlock
                          key={block.id}
                          block={block}
                          index={index}
                          onUpdate={updateBlock}
                          onRemove={removeBlock}
                          style={currentRelease.style}
                        />
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </div>
        </main>
      </div>

      <BlockToolbar onAddBlock={addBlock} />

      <ShareDialog
        isOpen={isShareDialogOpen}
        onClose={() => setIsShareDialogOpen(false)}
        shareUrl={shareUrl}
      />

      <PricingModal
        isOpen={isPricingModalOpen}
        onClose={() => setIsPricingModalOpen(false)}
        currentPlan={currentPlan}
        releasesCount={releasesCount}
      />
    </>
  );
}