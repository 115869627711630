import { compressToBase64, decompressFromBase64 } from 'lz-string';
import { PressRelease, Block } from '../types';
import { customAlphabet } from 'nanoid';
import { db } from '../lib/firebase';
import { doc, setDoc, getDoc, collection } from 'firebase/firestore';
import { auth } from '../lib/firebase';

// Create an interface for the compressed block data
interface CompressedBlock {
  type: Block['type'];
  content: string;
  mediaUrl?: string;
}

interface ShareData {
  data: string;
  timestamp: number;
  expiresAt: number;
  userId: string;
}

// Create a custom nanoid with a shorter alphabet for URL-friendly IDs
const generateShareId = customAlphabet('0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ', 10);

// 7 days in milliseconds
const SHARE_TTL = 7 * 24 * 60 * 60 * 1000;

export const createShareableLink = async (pressRelease: PressRelease): Promise<string> => {
  try {
    // Add auth check
    if (!auth.currentUser) {
      throw new Error('User must be authenticated to create share links');
    }

    const data = {
      title: pressRelease.title,
      blocks: pressRelease.blocks.map(block => ({
        type: block.type,
        content: block.content,
        mediaUrl: block.mediaUrl,
      })),
      date: pressRelease.date,
      style: pressRelease.style,
    };
    
    const compressed = compressToBase64(JSON.stringify(data));
    const shareId = generateShareId();
    
    // Add userId to the share document
    const shareData: ShareData = {
      data: compressed,
      timestamp: Date.now(),
      expiresAt: Date.now() + SHARE_TTL,
      userId: auth.currentUser.uid,
    };

    const shareRef = doc(collection(db, 'shares'), shareId);
    await setDoc(shareRef, shareData);
    
    return `${window.location.origin}/shared?share=${shareId}`;
  } catch (error) {
    console.error('Failed to create shareable link:', error);
    throw error;
  }
};

export const getSharedPressRelease = async (shareId: string): Promise<Partial<PressRelease> | null> => {
  try {
    // Get the share document from Firestore
    const shareRef = doc(collection(db, 'shares'), shareId);
    const shareDoc = await getDoc(shareRef);
    
    if (!shareDoc.exists()) {
      return null;
    }
    
    const shareData = shareDoc.data() as ShareData;
    
    // Check if share has expired
    if (Date.now() > shareData.expiresAt) {
      // Could optionally delete expired documents here
      // await deleteDoc(shareRef);
      return null;
    }
    
    const decompressed = decompressFromBase64(shareData.data);
    if (!decompressed) return null;
    
    const data = JSON.parse(decompressed);
    
    if (!data.title || !Array.isArray(data.blocks)) {
      throw new Error('Invalid press release data');
    }
    
    return {
      title: data.title,
      blocks: data.blocks.map((block: CompressedBlock) => ({
        id: crypto.randomUUID(),
        ...block,
      })),
      date: new Date(data.date),
      lastModified: new Date(),
      style: data.style || 'modern',
    };
  } catch (error) {
    console.error('Failed to parse shared press release:', error);
    return null;
  }
};