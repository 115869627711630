import React, { useEffect } from 'react';
import { X, Copy, Check, Building2, Link as LinkIcon, ExternalLink } from 'lucide-react';
import { PressRelease } from '../types';
import toast from 'react-hot-toast';

interface PreviewModeProps {
  pressRelease: PressRelease;
  onClose?: () => void;
}

// Add this helper function at the top of the file
const cleanTwitterUrl = (url: string): string => {
  console.log('Original URL:', url);
  
  // Remove @ if it exists at the start
  url = url.replace(/^@/, '');
  
  // Ensure the URL starts with https://
  if (!url.startsWith('http')) {
    url = 'https://' + url;
  }

  // Convert x.com to twitter.com
  url = url.replace('x.com', 'twitter.com');
  
  // Ensure it's a proper tweet URL
  if (!url.includes('/status/')) {
    console.warn('Invalid tweet URL format:', url);
  }
  
  console.log('Cleaned URL:', url);
  return url;
};

// Add getYouTubeId as a shared utility
const getYouTubeId = (url: string) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[2].length === 11 ? match[2] : null;
};

// Add these exports at the top of the file, before the PreviewMode component
export const getTypographyClass = (style?: string) => {
  return style === 'classic'
    ? 'font-serif tracking-normal leading-relaxed'
    : 'font-sans tracking-tight leading-normal';
};

export const renderBlock = (block: PressRelease['blocks'][0], style?: string) => {
  const typographyClass = getTypographyClass(style);
  
  switch (block.type) {
    case 'contact':
      return (
        <div className="flex justify-between items-start border-b border-gray-200 pb-6 mb-8">
          <div className="flex-1">
            <div className={`whitespace-pre-wrap text-sm text-gray-600 ${typographyClass}`}>
              {block.content}
            </div>
          </div>
          <div className="flex-shrink-0 ml-8">
            {block.mediaUrl ? (
              <img 
                src={block.mediaUrl} 
                alt="Company Logo" 
                className="w-32 h-32 object-contain"
              />
            ) : (
              <div className="w-32 h-32 bg-gray-100 rounded-lg flex items-center justify-center">
                <Building2 className="w-12 h-12 text-gray-400" />
              </div>
            )}
          </div>
        </div>
      );
    
    case 'headline':
      return (
        <h1 className={`text-3xl font-bold mb-6 ${
          style === 'classic' 
            ? 'font-serif text-gray-900 tracking-normal'
            : 'font-sans text-gray-900 tracking-tight bg-gradient-to-r from-gray-900 to-gray-700 bg-clip-text'
        }`}>
          {block.content}
        </h1>
      );
    
    case 'subheading':
      return (
        <h2 className={`text-2xl font-semibold mb-4 ${
          style === 'classic'
            ? 'font-serif text-gray-800 tracking-normal'
            : 'font-sans text-transparent bg-clip-text bg-gradient-to-r from-gray-800 to-gray-600 tracking-tight'
        }`}>
          {block.content}
        </h2>
      );
    
    case 'body':
      return (
        <p className={`mb-4 whitespace-pre-wrap ${
          style === 'classic'
            ? 'font-serif text-gray-700 leading-relaxed tracking-normal'
            : 'font-sans text-gray-700 leading-normal tracking-tight'
        }`}>
          {block.content}
        </p>
      );
    
    case 'quote':
      return (
        <blockquote className={`my-6 ${
          style === 'classic'
            ? 'font-serif border-l-4 border-gray-400 pl-4 italic'
            : 'font-sans border-l-4 border-indigo-500 pl-4 bg-gradient-to-r from-gray-800 to-gray-600 bg-clip-text'
        }`}>
          <p className={`text-lg ${
            style === 'classic'
              ? 'text-gray-700'
              : 'text-transparent'
          }`}>{block.content}</p>
        </blockquote>
      );
    
    case 'cta':
      return (
        <div className="my-6">
          <div className={`inline-block text-lg font-medium text-indigo-600 border-b-2 border-indigo-600 ${
            style === 'classic'
              ? 'font-serif tracking-normal'
              : 'font-sans tracking-tight'
          }`}>
            {block.content}
          </div>
        </div>
      );

    case 'footer':
      return (
        <div className="mt-8 pt-8 border-t border-gray-200">
          <div className={`prose prose-sm max-w-none text-gray-600 whitespace-pre-wrap ${typographyClass}`}>
            {block.content}
          </div>
        </div>
      );
    
    case 'image':
      return block.mediaUrl ? (
        <figure className="my-6">
          <img
            src={block.mediaUrl}
            alt={block.content || 'Press release image'}
            className="w-full h-auto rounded-lg"
          />
          {block.content && (
            <figcaption className={`mt-2 text-sm text-gray-500 text-center ${typographyClass}`}>
              {block.content}
            </figcaption>
          )}
        </figure>
      ) : null;
    
    case 'video':
      return block.mediaUrl ? (
        <figure className="my-6">
          <div className="aspect-video">
            <iframe
              src={block.mediaUrl}
              className="w-full h-full rounded-lg"
              allowFullScreen
              title={block.content || 'Press release video'}
            />
          </div>
          {block.content && (
            <figcaption className={`mt-2 text-sm text-gray-500 text-center ${typographyClass}`}>
              {block.content}
            </figcaption>
          )}
        </figure>
      ) : null;
    
    case 'twitter':
      const tweetUrl = cleanTwitterUrl(block.content);
      return block.content ? (
        <div className="my-6">
          <div className="twitter-tweet-container">
            <blockquote 
              className="twitter-tweet" 
              data-dnt="true"
              data-theme="light"
              data-align="center"
              data-conversation="none"
            >
              <a 
                href={tweetUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Loading Tweet...
              </a>
            </blockquote>
          </div>
          <div className="text-center text-sm text-gray-500 mt-2">
            <a 
              href={tweetUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="text-indigo-600 hover:text-indigo-700 flex items-center justify-center gap-1"
            >
              View on X
              <ExternalLink className="w-3 h-3" />
            </a>
          </div>
        </div>
      ) : null;

    case 'facebook':
      return block.content ? (
        <div className="my-6">
          <div className="fb-post" 
            data-href={block.content}
            data-width="100%"
            data-show-text="true">
          </div>
        </div>
      ) : null;

    case 'instagram':
      return block.content ? (
        <div className="my-6">
          <div className="instagram-media-container">
            <blockquote
              className="instagram-media"
              data-instgrm-captioned
              data-instgrm-permalink={block.content}
              data-instgrm-version="14"
              style={{ 
                background: '#FFF',
                border: '0',
                borderRadius: '3px',
                boxShadow: '0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15)',
                margin: '1px',
                maxWidth: '540px',
                minWidth: '326px',
                padding: '0',
                width: 'calc(100% - 2px)'
              }}
            >
              <div style={{ padding: '16px' }}>
                <a
                  href={block.content}
                  style={{ 
                    background: '#FFFFFF',
                    lineHeight: '0',
                    padding: '0 0',
                    textAlign: 'center',
                    textDecoration: 'none',
                    width: '100%'
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Loading Instagram post...
                </a>
              </div>
            </blockquote>
          </div>
        </div>
      ) : null;

    case 'youtube':
      return block.content ? (
        <div className="my-6 aspect-video">
          <iframe
            src={`https://www.youtube.com/embed/${getYouTubeId(block.content)}`}
            className="w-full h-full rounded-lg"
            allowFullScreen
            title="YouTube video"
          ></iframe>
        </div>
      ) : null;
    
    case 'link':
      const [url, title] = block.content.split('|');
      return url ? (
        <div className="my-4">
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center gap-2 text-indigo-600 hover:text-indigo-700"
          >
            <LinkIcon className="w-4 h-4" />
            <span>{title || url}</span>
            <ExternalLink className="w-3 h-3" />
          </a>
        </div>
      ) : null;
    
    default:
      return null;
  }
};

export default function PreviewMode({ pressRelease, onClose }: PreviewModeProps) {
  const [isCopied, setIsCopied] = React.useState(false);
  const contentRef = React.useRef<HTMLDivElement>(null);

  // Load social media scripts when component mounts
  useEffect(() => {
    const loadSocialScripts = () => {
      // Twitter
      if (!window.twttr) {
        // Initialize twttr queue
        window.twttr = {
          _e: [],
          ready: function(callback: any) {
            this._e.push(callback);
          }
        };

        const script = document.createElement('script');
        script.src = 'https://platform.twitter.com/widgets.js';
        script.async = true;
        script.charset = 'utf-8';
        
        script.onload = () => {
          if (window.twttr) {
            window.twttr.ready(() => {
              // Force reload widgets after a short delay
              setTimeout(() => {
                window.twttr.widgets.load();
              }, 100);
            });
          }
        };

        document.body.appendChild(script);
      } else {
        // If script already exists, try to reload widgets
        window.twttr.ready(() => {
          window.twttr.widgets.load();
        });
      }

      // Facebook
      if (!window.FB) {
        const script = document.createElement('script');
        script.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
      } else {
        // @ts-ignore
        window.FB.XFBML.parse();
      }

      // Instagram
      if (!window.instgrm) {
        const script = document.createElement('script');
        script.src = '//www.instagram.com/embed.js';
        script.async = true;
        document.body.appendChild(script);
      } else {
        // @ts-ignore
        window.instgrm.Embeds.process();
      }
    };

    loadSocialScripts();

    // Cleanup function
    return () => {
      const scripts = document.querySelectorAll('script[src*="twitter"], script[src*="platform.twitter"]');
      scripts.forEach(script => script.remove());
      // Clean up twttr object
      delete window.twttr;
    };
  }, []);

  const handleCopyContent = async () => {
    if (!contentRef.current) return;

    try {
      const content = contentRef.current.innerText;
      await navigator.clipboard.writeText(content);
      setIsCopied(true);
      toast.success('Content copied to clipboard!');
      setTimeout(() => setIsCopied(false), 2000);
    } catch (error) {
      toast.error('Failed to copy content');
      console.error('Copy failed:', error);
    }
  };

  // Update the component to use the exported functions
  const renderBlockInPreview = (block: PressRelease['blocks'][0]) => {
    return renderBlock(block, pressRelease.style);
  };

  return (
    <div className="fixed inset-0 bg-gray-900/30 backdrop-blur-sm flex items-center justify-center z-[201]">
      <div className="bg-white rounded-xl shadow-2xl max-w-3xl w-full mx-4 max-h-[90vh] overflow-y-auto relative z-[202]">
        <div className="sticky top-0 z-[203] bg-white border-b border-gray-200 p-4 flex items-center justify-between">
          <h2 className="text-lg font-semibold text-gray-900">Preview Mode</h2>
          <div className="flex items-center gap-2">
            <button
              onClick={handleCopyContent}
              className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors"
            >
              {isCopied ? (
                <>
                  <Check className="w-4 h-4" />
                  Copied!
                </>
              ) : (
                <>
                  <Copy className="w-4 h-4" />
                  Copy
                </>
              )}
            </button>
            <button
              onClick={onClose}
              className="p-2 text-gray-400 hover:text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
            >
              <X className="w-5 h-5" />
              <span className="sr-only">Close preview</span>
            </button>
          </div>
        </div>
        <article className="p-8" ref={contentRef} data-preview-content>
          <div className={`prose prose-lg max-w-none prose-headings:text-gray-900 prose-p:text-gray-700 ${getTypographyClass()}`}>
            {pressRelease.blocks.map((block) => (
              <div key={block.id}>{renderBlockInPreview(block)}</div>
            ))}
          </div>
        </article>
      </div>
    </div>
  );
}

// Add these type definitions at the top of the file
declare global {
  interface Window {
    twttr: any;
    FB: any;
    instgrm: any;
  }
}