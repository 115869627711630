import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getSharedPressRelease } from '../utils/share';
import { PressRelease } from '../types';
import { Newspaper } from 'lucide-react';
import { renderBlock, getTypographyClass } from './PreviewMode';

// Create an interface for the shared press release that includes required fields
interface SharedPressRelease extends Partial<PressRelease> {
  title: string;  // These fields are guaranteed to exist based on our share.ts logic
  blocks: PressRelease['blocks'];
  style: PressRelease['style'];
}

export default function SharedPressReleaseView() {
  const [searchParams] = useSearchParams();
  const [pressRelease, setPressRelease] = useState<SharedPressRelease | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadSharedRelease = async () => {
      try {
        const shareParam = searchParams.get('share');
        if (!shareParam) {
          setError('No shared press release found');
          return;
        }

        const sharedRelease = await getSharedPressRelease(shareParam);
        if (!sharedRelease || !sharedRelease.title || !sharedRelease.blocks) {
          setError('Invalid or expired share link');
          return;
        }

        // Cast to SharedPressRelease since we've verified the required fields exist
        setPressRelease(sharedRelease as SharedPressRelease);
      } catch (error) {
        console.error('Error loading shared release:', error);
        setError('Failed to load shared press release');
      } finally {
        setIsLoading(false);
      }
    };

    loadSharedRelease();
  }, [searchParams]);

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-2xl font-bold text-gray-900 mb-2">Error</h1>
          <p className="text-gray-600">{error}</p>
        </div>
      </div>
    );
  }

  if (!pressRelease) return null;

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 via-indigo-50 to-purple-50">
      {/* Header */}
      <header className="fixed top-0 left-0 right-0 z-30 bg-white/70 backdrop-blur-md border-b border-gray-200/50">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex items-center justify-between h-14">
            <div className="flex items-center">
              <Newspaper className="w-8 h-8 bg-gradient-to-r from-blue-600 to-indigo-600 text-white p-1.5 rounded-lg" />
              <h1 className="ml-2 text-xl font-bold bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent">
                Releaseify
              </h1>
            </div>
          </div>
        </div>
      </header>

      {/* Content */}
      <main className="pt-24 pb-16 max-w-4xl mx-auto px-6">
        <div className={`bg-white rounded-lg shadow-sm border border-gray-200 p-8 mx-auto ${
          pressRelease.style === 'classic' ? 'font-serif' : 'font-sans'
        }`}>
          <h1 className={`text-3xl font-bold mb-6 ${getTypographyClass(pressRelease.style)}`}>
            {pressRelease.title}
          </h1>
          
          {pressRelease.blocks.map((block) => (
            <div key={block.id} className="mb-6">
              {renderBlock(block, pressRelease.style)}
            </div>
          ))}
        </div>
      </main>
    </div>
  );
} 